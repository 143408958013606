export default function PropertyType({wrapperClass,label='اختر نوع المنشأة',value='residential',setValue}){

    return (
        <div className={`w-[100%] mb-[10px] relative ${wrapperClass}`}>
            <label className="text-[20px]">{label}</label>
            <div className="text-center">
                <div className="flex gap-[0] w-full max-w-[330px] rounded-[40px] items-center mx-auto shadow-[0px_8px_24px_0px_rgba(0,0,0,0.07)]">
                    <div className={`w-[50%] h-[40px] bg-white rounded-[30px] leading-[40px] text-center text-[20px] text-grey-900 font-[200] select-none cursor-pointer ${value === 'residential' ? '!bg-darkBlue !text-white !font-[600]' : ''}`} onClick={()=>setValue('residential')}>منشأة سكنية</div>
                    <div className={`w-[50%] h-[40px] bg-white rounded-[30px] leading-[40px] text-center text-[20px] text-grey-900 font-[200] select-none cursor-pointer ${value === 'commercial' ? '!bg-darkBlue !text-white !font-[600]' : ''}`} onClick={()=>setValue('commercial')}>منشأة تجارية</div>
                </div>
            </div>
        </div>
    )
}