export default function CheckBox({wrapperClass,label,text,checked,setChecked,isRequired=false}){

    return (
        <div className={`h-[40px] w-[100%] mb-[20px] mt-[24px] relative ${wrapperClass}`}>
            <label className="mb-[1px] block">{label}{isRequired && <span className="text-danger">*</span>}</label>
            <div className="flex whitespace-nowrap gap-[10px] items-center cursor-pointer" onClick={()=>setChecked(!checked)}>
                <div className="w-[20px] h-[20px] bg-darkBlue rounded-[6px]">
                    {checked && <svg className="ms-[1px] mt-[1px] scale-75" xmlns="http://www.w3.org/2000/svg" viewBox="0,0,256,256" width="16px" height="16px" fillRule="nonzero">
                        <g fill="#ffffff" fillRule="nonzero" stroke="none" strokeWidth="2" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{mixBlendMode:"normal"}}>
                            <g transform="scale(5.12,5.12)">
                                <path d="M41.9375,8.625c-0.66406,0.02344 -1.27344,0.375 -1.625,0.9375l-18.8125,28.78125l-12.1875,-10.53125c-0.52344,-0.54297 -1.30859,-0.74609 -2.03125,-0.51953c-0.71875,0.22266 -1.25391,0.83203 -1.37891,1.57422c-0.125,0.74609 0.17578,1.49609 0.78516,1.94531l13.9375,12.0625c0.4375,0.37109 1.01563,0.53516 1.58203,0.45313c0.57031,-0.08594 1.07422,-0.41016 1.38672,-0.89062l20.09375,-30.6875c0.42969,-0.62891 0.46484,-1.44141 0.09375,-2.10547c-0.37109,-0.66016 -1.08594,-1.05469 -1.84375,-1.01953z"/>
                            </g>
                        </g>
                    </svg>}
                </div>
                <div className="w-full text-grey-600 text-[16px] select-none">{text}</div>
            </div>
        </div>
    )
}