import AppLayout from "./layouts/appLayout";
import AuthLayout from "./layouts/authLayout";
import EmptyLayout from "./layouts/emptyLayout";
import NotFoundPage from "./pages/404";
import LoginPage from "./pages/auth/login";
import RegisterPage from "./pages/auth/register";
import RegisterSuccess from "./pages/auth/registerSuccess";
import HomePage from "./pages/dashboard/home";
import HelpHomePage from "./pages/help";
import MaintenanceHomePage from "./pages/maintenance";
import MessagesHomePage from "./pages/messages";
import PropertyHomePage from "./pages/property";
import PropertyAddPage from "./pages/property/add";
import SingleProperty from "./pages/property/singleProperty";
import UnitAddPage from "./pages/property/unit/add";
import UnitEditPage from "./pages/property/unit/edit";
import SingleUnit from "./pages/property/unit/singleUnit";
import RequestsHomePage from "./pages/requests";
import RequestAddPage from "./pages/requests/add";
import SingleRequestPage from "./pages/requests/singleRequest";
import SingleSubRequestPage from "./pages/requests/singleSubRequest";
import SettingsHomePage from "./pages/settings";
import UsersHomePage from "./pages/users";
import UsersAddPage from "./pages/users/add";
import UsersEditPage from "./pages/users/edit";
import WalletPage from "./pages/wallet";
import PropertyEditPage from "./pages/property/edit";
import SubPropertyAddPage from "./pages/property/addSubProperty";
import PerventiveVisitsHomePage from "./pages/preventive-visits";
import PerventiveVisitsAddPage from "./pages/preventive-visits/add";

const routesConfig = [
    {
        element: <AuthLayout />,
        children: [
            {
                path: "/:lng/login",
                element: <LoginPage />,
            },
            {
                path: "/:lng/register",
                element: <RegisterPage />,
            },
        ],
    },
    {
      element: <AppLayout />,
      children: [
        {
          path: "/:lng/",
          element: <HomePage />,
        },
        {
            path: "/",
            element: <HomePage />,
        },
        {
            path: "/:lng/dashboard",
            element: <HomePage />,
        },
        {
          path:'/:lng/users',
          element: <UsersHomePage />
        },
        {
          path:'/:lng/users/add',
          element: <UsersAddPage />
        },
        {
          path:'/:lng/users/edit/:id',
          element: <UsersEditPage />
        },
        {
          path:'/:lng/property',
          element: <PropertyHomePage />
        },
        {
          path:'/:lng/property/:id',
          element: <SingleProperty />
        },
        {
          path:'/:lng/property/add',
          element: <PropertyAddPage />
        },
        {
          path:'/:lng/unit/:id',
          element: <SingleUnit />
        },
        {
          path:'/:lng/property/:id/addUnit',
          element: <UnitAddPage />
        },
        {
          path:'/:lng/property/edit/:id',
          element: <PropertyEditPage />
        },
        {
          path:'/:lng/property/addSubProperty/:id',
          element: <SubPropertyAddPage />
        },
        {
          path:'/:lng/unit/edit/:id',
          element: <UnitEditPage />
        },
        {
          path:'/:lng/preventive-visits',
          element: <PerventiveVisitsHomePage />
        },
        {
          path:'/:lng/preventive-visits/add',
          element: <PerventiveVisitsAddPage />
        },
        {
          path:'/:lng/messages',
          element: <MessagesHomePage />
        },
        {
          path:'/:lng/requests',
          element: <RequestsHomePage />
        },
        {
          path:'/:lng/requests/:id',
          element: <SingleRequestPage />
        },
        {
          path:'/:lng/subRequest/:id',
          element: <SingleSubRequestPage />
        },
        {
          path:'/:lng/requests/add',
          element: <RequestAddPage />
        },
        {
          path:'/:lng/maintenance',
          element: <MaintenanceHomePage />
        },
        {
          path:'/:lng/help',
          element: <HelpHomePage />
        },
        {
          path:'/:lng/settings',
          element: <SettingsHomePage />
        },
        {
          path:'/:lng/wallet',
          element: <WalletPage />
        }
      ],
    },
    {
      element: <EmptyLayout />,
      children: [
        {
          path: "*",
          element: <NotFoundPage />,
        },
        {
          path: "/:lng/register-success",
          element: <RegisterSuccess />
        }
      ],
    },
  ];

  export default routesConfig;