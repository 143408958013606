export default function NoHeadBoxContainer({children, ...props}) {
        
    return (
        <div className="px-[15px]">
        <div className="relative">
            <div className="p-[24px] rounded-[8px] border-[1px] border-grey-200 bg-white">
                {children}
            </div>
        </div>
        </div>
    )
}