import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import useDocumentTitle from "../../components/helpers/useDocumentTitle";
import PageHeader from "../../components/shared/PageHeader";
import PageMain from "../../containers/shared/PageMain";
import apiClient from "../../components/utils/apiClient";
import MainContent from "../../containers/shared/MainContent";
import NormalFormContainer from "../../containers/shared/NormalFormContainer";
import InputField from "../../components/shared/InputField";
import SelectField from "../../components/shared/SelectField";
import ModalContainer from "../../containers/shared/ModalContainer";
import NormalButton from "../../components/shared/NormalButton";
import SingleImageUpload from "../../components/shared/SingleImageUpload";
import TextArea from "../../components/shared/TextArea";
import SingleImageCompress from "../../components/helpers/SingleImageCompress";
import {initializeApp, getApp, getApps} from "firebase/app";
import { getStorage } from "firebase/storage";
import { getDownloadURL, uploadBytesResumable, ref } from "firebase/storage";
import { firebaseConfig } from "../../components/utils/constants";
import moment from "moment";
import Loading from "../../components/shared/Loading";
import Toast from "../../components/shared/Toast";
import PropertyType from "../../components/shared/PropertyType";
import CheckBox from "../../components/shared/CheckBox";
import MapAutocomplete from "../../components/shared/MapAutocomplete";
import CounterInput from "../../components/shared/CounterInput";
import { useAppLatyoutContext } from "../../layouts/appLayout";
import CityConverter from "../../components/helpers/CityConverter";


const SubPropertyAddPage = () => {
    const {t,i18n} = useTranslation();
    useDocumentTitle('إنشاء منشأة فرعية جديدة');
    const { id } = useParams();
    const navigate = useNavigate();
    const pageTitle = {title: 'إنشاء منشأة فرعية جديدة', subTitle: 'يمكنك إنشاء منشأتك الفرعية من هنا'};
    const [addPropertyForm,setAddPropertyForm] = useState({name: '', description: ''});
    const [errors, setErrors] = useState(null);
    const [roleInfoModal, setRoleInfoModal] = useState(false);
    const [isAddPropertyFormDisabled, setIsAddPropertyFormDisabled] = useState(true);
    const [uploadPreview, setUploadPreview] = useState(null);
    const [compressedImage, setCompressedImage] = useState(null);
    const [showLoading, setShowLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [PropertyId, setPropertyId] = useState(null);
    const [toast, setToast] = useState({show: false,type: null, message:'' });

    const [propertyType,setPropertyType] = useState(null);
    const [propertyText,setPropertyText] = useState(null);
    const [propertyTypeOptions, setPropertyTypeOptions] = useState([]);

    const [allowSubProperty,setAllowSubProperty] = useState(true);
  
    const [addressDetails, setAddressDetails] = useState(null);

    const [property, setProperty] = useState(null);

    const [numberOfUnits,setNumberOfUnits] = useState(0);
    const [subPropertyTitle,setSubPropertyTitle] = useState(null);
    const [subPropertyUnitTitle,setSubPropertyUnitTitle] = useState(null);




    const appLatyoutContext = useAppLatyoutContext();

    // const getPropertyTypes = async () => {
    //   try {
    //     const response = await apiClient.get(`/b-properties/types?category=${propertyType}`);
    //     if(response?.data) {
    //         const options = response?.data?.map((option) => ({
    //             value: option?._id,
    //             label: option?.name
    //         }));
    //         setPropertyTypeOptions(options.reverse());
    //     } else {

    //     }
    //   } catch(err) {}
    // }


    const getProperty = async () => {
        try {
          const response = await apiClient.get(`/b-properties/${id}`);
          if(response?.data) {
            setProperty(response?.data);
          } else {
  
          }
        } catch(err) {}
      }

    useEffect(()=>{
        getProperty();
    },[])


    useEffect(()=>{
      if(addPropertyForm && addPropertyForm?.name) {
        setIsAddPropertyFormDisabled(false);
      } else {
        setIsAddPropertyFormDisabled(true);
      }
    },[addPropertyForm]);
  
    const hideError = (err) => {
      if(errors) {
        let errorsList = errors;
        delete errorsList[err];
        setErrors(errorsList);
      }
    }


    const handleCloseModal = () => {
        setRoleInfoModal(false);
    }



    function handleChangeUpload(e) {
        SingleImageCompress(e.target.files[0]).then(compressedFile=>setCompressedImage(compressedFile));
        setUploadPreview(URL.createObjectURL(e.target.files[0]));
    }


    const handleSubmitAddProperty = () => {
        setShowLoading(true);
        setIsAddPropertyFormDisabled(true);
        if(compressedImage) {
            const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
            const storage = getStorage(app);
            const file = compressedImage;
            const time = Date.parse(moment().locale('en').format('YYYY-MM-DD HH:mm:ss')) / 1000;
            const storageRef = ref(storage,'fanni-business/propertyImages/'+time+'_'+file.name);
            const uploadTask = uploadBytesResumable(storageRef, file);
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                },
                (error) => {
                    setShowLoading(false);
                    setToast({show:true,type:'error',message:'حدث خطأ في رفع الصورة ,من فضلك حاول مرة اخري في وقت لاحق'});
                },
                () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadedURL) => {
                    handleSubmitAddPropertyAction(downloadedURL);
                });
                }
            );
        } else {
            handleSubmitAddPropertyAction();
        }
    }

    const handleSubmitAddPropertyAction = async (ImageUrl=null) => {
        setErrors(null);
        const finalAddPropertyForm = JSON.parse(JSON.stringify(addPropertyForm));
        finalAddPropertyForm.parentId = id;
        if(ImageUrl) {
            finalAddPropertyForm.files = [ImageUrl];
        }
        finalAddPropertyForm.category = property?.category;
        finalAddPropertyForm.propertyType = propertyType;
        finalAddPropertyForm.addressDetails = addressDetails;
        finalAddPropertyForm.numberOfUnits = numberOfUnits;
        finalAddPropertyForm.description = 'منشأة فرعية';
        if(finalAddPropertyForm?.addressDetails?.city) {
            finalAddPropertyForm.addressDetails.city = CityConverter(finalAddPropertyForm?.addressDetails?.city,'ar');
        }
        finalAddPropertyForm.addressDetails.addressLine = finalAddPropertyForm.address;
        try {
        const response = await apiClient.post(`/b-properties`,finalAddPropertyForm);
        if(response?.data) {
            setShowLoading(false);
            setPropertyId(response?.data?._id);
            setSuccessMessage(true);
        } else {
            setIsAddPropertyFormDisabled(false);
            setShowLoading(false);
        }
        } catch(err) {
            setIsAddPropertyFormDisabled(false);
            setShowLoading(false);
            if(err?.response?.data?.errors) {
                let errorsList = {};
                err?.response?.data?.errors?.map(err=>{
                    errorsList[err.field] = err.message; 
                });
                setErrors(errorsList);
            }
        }
    }
    

    const handleClickPropertyDetails = () => {
        navigate(`/ar/property/${PropertyId}`);
      }

    //   useEffect(()=>{
    //     if(propertyType) {
    //         if(propertyType === 'residential') {
    //           setPropertyText('السكنية');
    //         } else {
    //           setPropertyText('التجارية');
    //         }
    //         setAddPropertyForm({ ...addPropertyForm, propertyType: '' });
    //         // getPropertyTypes();
    //     }
    //   },[propertyType])


      useEffect(()=>{

        if(property,appLatyoutContext?.propertyTypes?.residential,appLatyoutContext?.propertyTypes?.commercial) {
            const subPropertyId = property?.propertyType?.category === 'commercial' 
                                   ? appLatyoutContext?.propertyTypes?.commercial?.find(subProperty => subProperty?._id === property?.propertyType?._id)?.children[0]?._id
                                   : appLatyoutContext?.propertyTypes?.residential?.find(subProperty => subProperty?._id === property?.propertyType?._id)?.children[0]?._id;
            const subPropertyTitle = property?.propertyType?.category === 'commercial' 
                                   ? appLatyoutContext?.propertyTypes?.commercial?.find(subProperty => subProperty?._id === property?.propertyType?._id)?.children[0]?.name
                                   : appLatyoutContext?.propertyTypes?.residential?.find(subProperty => subProperty?._id === property?.propertyType?._id)?.children[0]?.name;
            const subPropertyUnitTitle = property?.propertyType?.category === 'commercial' 
                                   ? appLatyoutContext?.propertyTypes?.commercial?.find(subProperty => subProperty?._id === property?.propertyType?._id)?.children[0]?.unitDisplayName
                                   : appLatyoutContext?.propertyTypes?.residential?.find(subProperty => subProperty?._id === property?.propertyType?._id)?.children[0]?.unitDisplayName;
            setPropertyType(subPropertyId);
            setSubPropertyTitle(subPropertyTitle)
            setSubPropertyUnitTitle(subPropertyUnitTitle)
        }

      },[property,appLatyoutContext?.propertyTypes])


      
      if(!propertyType && !subPropertyTitle && !subPropertyUnitTitle) {
        return;
      }




  return (
    <>
        <div>
        <PageHeader pageTitle={pageTitle} />
        <PageMain>
            <MainContent>
                <NormalFormContainer heading="من فضلك قم بملئ البيانات التالية لإنشاء منشأة فرعية جديدة." label={`اضف ${subPropertyTitle ? subPropertyTitle : 'منشأة فرعية'}`}>

                    {/* <PropertyType value={propertyType} setValue={setPropertyType} /> */}

                    {/* <div className="mb-[15px]">
                        <SelectField isRequird label={'إختار نوع المنشأة '+propertyText} placeholder={'اختر نوع المنشأة'} options={propertyTypeOptions} value={addPropertyForm?.propertyType} onChange={(e)=>{setAddPropertyForm({ ...addPropertyForm, propertyType: e?.target?.value }); hideError('propertyType')}} />
                    </div>
                    
                    {propertyTypeOptions[propertyTypeOptions?.findIndex(option=>option?.value === addPropertyForm?.propertyType)]?.label === 'أخرى' && <div className="mb-[15px]">
                        <CheckBox label={'اضافة منشأت فرعية'} isRequired text={'نعم ساضيف منشأت فرعية للمنشأة الرئيسية'} checked={allowSubProperty} setChecked={()=>setAllowSubProperty(!allowSubProperty)} />
                    </div>} */}

                    {/* <div className="mb-[15px]">
                        <SingleImageUpload onchange={handleChangeUpload} preview={uploadPreview} label={'صورة المنشأة (اختياري)'} subLabel={'تساعد صورة المنشأة على تمييز منشأتك'} info={'تسمح برفع png, jpg, jpeg'} />
                    </div> */}

                    <div className="mb-[15px]">
                        <InputField isRequird label={` اسم ${subPropertyTitle ? subPropertyTitle : 'المنشأة الفرعية'}`} placeholder={'اكتب الاسم'} value={addPropertyForm?.name} error={errors?.name} errorMessage={errors?.name} translateError={true} onChange={(e)=>{setAddPropertyForm({ ...addPropertyForm, name: e.target.value }); hideError('name')}} />
                    </div>

                    {/* <div className="mb-[15px]">
                        <TextArea label={'وصف المنشأة الفرعية'} placeholder={'مثال لنص يمكن استخدامة كوصف للمنشأة الفرعية'} value={addPropertyForm?.description} error={errors?.description} errorMessage={errors?.description} translateError={true} onChange={(e)=>{ setAddPropertyForm({ ...addPropertyForm, description: e.target.value }); hideError('description')}} />
                    </div> */}

                    <div className="mb-[15px]">
                        <InputField isRequird label={` عنوان ${subPropertyTitle ? subPropertyTitle : 'المنشأة الفرعية'} الوطني`} placeholder={'اكتب العنوان'} value={addPropertyForm?.address} error={errors?.address} errorMessage={errors?.address} translateError={true} onChange={(e)=>{setAddPropertyForm({ ...addPropertyForm, address: e.target.value }); hideError('address')}} />
                    </div>

                    <div className="mb-[15px]">
                        <CounterInput label={`عدد ${subPropertyUnitTitle ? subPropertyUnitTitle : 'الوحدات'}`} value={numberOfUnits} setValue={setNumberOfUnits} isRequired={true} /> 
                    </div>

                    <div className="mb-[15px]">
                        <label className="mb-[5px] block">موقع {subPropertyTitle ? subPropertyTitle : 'المنشأة الفرعية'} علي الخريطة<span className="text-danger">*</span></label>
                        <MapAutocomplete error={errors?.addressDetails} errorMessage={errors?.addressDetails} setAddressDetails={setAddressDetails} />
                    </div>

                    <div className="text-end">
                        <NormalButton label={`إنشاء ${subPropertyTitle ? subPropertyTitle : 'منشأة فرعية'}`} onClick={handleSubmitAddProperty} isDisabled={isAddPropertyFormDisabled} />
                    </div>

                </NormalFormContainer>
            </MainContent>
        </PageMain>
        </div>
        { successMessage && <ModalContainer hideX={true} maxWidth={'600px'} handleCloseModal={handleCloseModal}>
            <div className="text-center mb-[16px]">
                <span className="block w-[128px] h-[128px] m-auto !bg-center !bg-no-repeat" style={{background:"url('/assets/images/success.svg')"}} ></span>
            </div>
            <div className="text-center text-grey-900 mb-[80px]">
                <p className="font-[700] text-[30px] leading-[185%]">تهانينا !</p>
                <p className="font-[500] text-[24px] leading-[185%]">لقد قمت بإنشاء منشأتك بنجاح.</p>
            </div>
            <div className="text-center bg-grey-100 absolute bottom-0 left-0 w-full h-[96px] pt-[24px]">
                <NormalButton label={'الذهاب الي تفاصيل المنشأة'} onClick={handleClickPropertyDetails} color={'!bg-grey-800'} />
            </div>
        </ModalContainer> }
        <Toast toast={toast} setToast={setToast} />
        <Loading showLoading={showLoading} />
    </>
  );
};

export default SubPropertyAddPage;
