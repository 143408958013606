import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import useDocumentTitle from "../../components/helpers/useDocumentTitle";
import PageHeader from "../../components/shared/PageHeader";
import PageMain from "../../containers/shared/PageMain";
import apiClient from "../../components/utils/apiClient";
import MainContent from "../../containers/shared/MainContent";
import UnitBar from "../../components/property/unit/UnitBar";
import UnitCard from "../../components/property/unit/UnitCard";
import BoxContainer from "../../containers/shared/BoxContainer";
import Button from "../../components/shared/Button";
import PropertyBar from "../../components/property/PropertyBar";
import PropertyCard from "../../components/property/PropertyCard";
import { useAppLatyoutContext } from "../../layouts/appLayout";

const SingleProperty = () => {

  const appLatyoutContext = useAppLatyoutContext();


    const {t,i18n} = useTranslation();
    const { id } = useParams();
    const [property, setProperty] = useState(null);
    const [units, setUnits] = useState(null);
    const [subProperties, setSubProperties] = useState([]);
    useDocumentTitle(property?.name);
    const navigate = useNavigate();
    const pageTitle = {title: property?.name, subTitle: property?.propertyType ? !property?.parentId ? `<div style="color:#fff;background:#042254;display:inline-block;padding:0 10px; border-radius:15px; line-height:30px; font-size:14px;">${property?.propertyType?.category === 'residential' ? 'منشأة سكنية' : 'منشأة تجارية'}</div>  <div style="color:#fff;background:#A15933;display:inline-block;padding:0 10px; border-radius:15px; line-height:30px; font-size:14px; margin:0 5px">${property?.propertyType?.name}</div>` : '' : property?.description };
    const [parentPropertyType, setParentPropertyType] = useState(null);
    
    const getProperty = async () => {
        try {
          const response = await apiClient.get(`/b-properties/${id}`);
          if(response?.data) {
            setProperty(response?.data);
            setUnits(response?.data?.units?.filter(unit=> !unit?.deletedAt));
            setParentPropertyType(response?.data?.propertyType);
            if(response?.data?.subProperties) {
              setSubProperties(response?.data?.subProperties);
            }
          } else {
  
          }
        } catch(err) {}
      }

    useEffect(()=>{
      if(id) {
        getProperty();
      }
    },[id])

    const handleClickUnitCard = (UnitId) => {
      navigate(`/ar/unit/${UnitId}`);
    }

    const handleClickEditUnit = () => {
      navigate(`/ar/property/edit/${id}`);
    }

    const handleClickPropertyCard = (id) => {
      navigate(`/ar/property/${id}`);
    }

    const subPropertyDisplayName = property?.propertyType?.category === 'commercial' 
                                   ? appLatyoutContext?.propertyTypes?.commercial?.find(subProperty => subProperty?._id === property?.propertyType?._id)?.children[0]?.name
                                   : appLatyoutContext?.propertyTypes?.residential?.find(subProperty => subProperty?._id === property?.propertyType?._id)?.children[0]?.name;
    const subPropertyUnitDisplayName = property?.propertyType?.category === 'commercial' 
                                   ? appLatyoutContext?.propertyTypes?.commercial?.find(subProperty => subProperty?._id === property?.propertyType?._id)?.children[0]?.unitDisplayName
                                   : appLatyoutContext?.propertyTypes?.residential?.find(subProperty => subProperty?._id === property?.propertyType?._id)?.children[0]?.unitDisplayName;


  return (
    <>
        <div>
        <PageHeader pageTitle={pageTitle} isColored />
        <PageMain>
            <div className="absolute top-0 left-0 w-full h-full bg-grey-50 z-0"></div>
            <MainContent>
            <BoxContainer label="تفاصيل المنشأة" propertyDetails={true} editText={'تعديل بيانات المنشأة'} propertyEditLink={`/ar/property/edit/${id}`}>
                    <div className="flex flex-nowrap justify-between self-stretch items-center gap-x-[16px] pb-[24px] mb-[24px] border-b-[1px] border-b-grey-300">
                        <div className="max-w-[70%]">
                            <h2 className="text-[24px] text-grey-900 font-[700] leading-[36px] mb-[5px]">{property?.name && property?.name}</h2>
                            {!property?.parentId && <p className="text-[13px] text-grey-950 font-[500] leading-[20px] whitespace-nowrap truncate overflow-hidden">{property?.description && property?.description}</p>}
                        </div>
                        <div>
                            <div className="w-[324px]">
                                {/* <Button text={'تعديل بيانات الوحدة'} bgColor={'!bg-grey-800'} onClick={()=>handleClickEditUnit()} /> */}
                            </div>
                        </div>
                    </div>
                    <div className="bg-grey-50 rounded-[8px] px-[16px] py-[8px] text-grey-600 mb-[24px]">
                        <h4 className="mb-[4px] text-[24px] font-[500]">{property?.addressDetails?.addressLine || 'مثال لعنوان للوحدة يمكن تغييره في وقت لاحق'}</h4>
                        <p className="text-[14px] font-[500]"><span className="inline-block align-middle w-[16px] h-[16px] me-[4px] !bg-center !bg-no-repeat brightness-50" style={{background:"url('/assets/images/location.svg')", backgroundSize: "16px"}}></span> العنوان التفصيلي</p>
                    </div>
                    <div className="h-[61px] flex flex-nowrap">
                        {property?.propertyType?.allowSubProperty && <><div className="text-[25px] font-[600] text-grey-900">
                          {property?.subProperties?.length || 0}
                            <span className="block mt-[4px] font-[500] text-[14px] text-grey-600">
                                <span className="w-[16px] h-[16px] inline-block align-middle !bg-center !bg-no-repeat me-[4px]" style={{background: "url('/assets/images/buildings.svg')"}}></span>
                                { subPropertyDisplayName ? subPropertyDisplayName : 'عدد المنشآت الفرعية'}
                            </span>
                        </div>
                        <div className="relative w-[193px] h-[61px]"><span className="block w-[1px] h-[40px] bg-grey-300 absolute top-[10px] left-[96px]"></span></div></>}
                        <div className="text-[25px] font-[600] text-grey-900">
                            {property?.numberOfUnits || 0}
                            <span className="block mt-[4px] font-[500] text-[14px] text-grey-600">
                                <span className="w-[16px] h-[16px] inline-block align-middle !bg-center !bg-no-repeat me-[4px] brightness-50" style={{background: "url('/assets/images/building.svg')"}}></span>
                                { property?.subProperties?.length > 0 ? subPropertyUnitDisplayName ? subPropertyUnitDisplayName : 'عدد الوحدات' : property?.propertyType?.unitDisplayName ? property?.propertyType?.unitDisplayName : 'عدد الوحدات' }
                            </span>
                        </div>
                        { !property?.parentId && <><div className="relative w-[193px] h-[61px]"><span className="block w-[1px] h-[40px] bg-grey-300 absolute top-[10px] left-[96px]"></span></div> 
                        <div className="text-[25px] font-[600] text-grey-900">
                            {property?.numberOfRequests || 0}
                            <span className="block mt-[4px] font-[500] text-[14px] text-grey-600">
                                <span className="w-[16px] h-[16px] inline-block align-middle !bg-center !bg-no-repeat me-[4px] brightness-50" style={{background: "url('/assets/images/note.svg')"}}></span>
                                عدد الطلبات
                            </span>
                        </div></>}
                    </div>
                </BoxContainer>
              {property?.propertyType?.allowSubProperty ?
              <>
              <div className="pt-[25px]">
                <PropertyBar propertiesCount={property?.unitsCount || 0} propertyLabel={`${subPropertyDisplayName ? subPropertyDisplayName : 'منشأة فرعية'}`} subProperty={true} addSubPropertyLink={`/ar/property/addSubProperty/${id}`} ButtonLabel={`إضافة ${subPropertyDisplayName ? subPropertyDisplayName : 'منشأة فرعية'}`} />
                {subProperties !== null && Array.isArray(subProperties) && subProperties?.length < 1 && 
                <div className="pt-[80px] text-center">
                  <div className="inline-block w-[188px] h-[188px] !bg-contain !bg-center" style={{background: "url('/assets/images/main-property-icon.svg')"}}></div>
                  <div className="w-full text-center text-grey-900 text-[24px] font-[500] mb-[25px]">لم تقم بإضافة اي {`${subPropertyDisplayName ? subPropertyDisplayName : 'منشأة فرعية'}`} حتي الأن</div>
                  <Link className="inline-block min-w-[320px] h-[48px] leading-[48px] rounded-[8px] text-white bg-grey-800 text-[18px] font-[600]" to={`/ar/property/addSubProperty/${id}`}>{`إضافة ${subPropertyDisplayName ? subPropertyDisplayName : 'منشأة فرعية'}`}</Link>
                </div> }
                {subProperties !== null && Array.isArray(subProperties) && subProperties?.length > 0 && 
                <div className="pt-[25px] flex  gap-[32px] flex-wrap">
                  {subProperties?.map(((property,index)=>{
                    return <PropertyCard key={`property-${index}`} 
                                        parentType={parentPropertyType}
                                        image={property?.files?.length > 0 && property?.files[0]} 
                                        name={property?.name} 
                                        description={property?.description} 
                                        units={property?.numberOfUnits} 
                                        propertyType={property?.propertyType} 
                                        showDescription={false}
                                        requests={property?.requests?.length} 
                                        subProperties={property?.subProperties}
                                        commercialData={appLatyoutContext?.propertyTypes?.commercial}
                                        residentialData={appLatyoutContext?.propertyTypes?.residential}
                                        onClick={()=>handleClickPropertyCard(property?._id)} />
                  }))}
                </div> }
              </div>
              </>
            :
            <>
            <div className="pt-[25px]">
            <UnitBar unitsCount={property?.numberOfUnits} unitLabel={`${property?.propertyType?.unitDisplayName ? property?.propertyType?.unitDisplayName : 'وحدة'}`} ButtonLink={`/ar/property/${id}/addUnit`} ButtonLabel={`إضافة ${property?.propertyType?.unitDisplayName ? property?.propertyType?.unitDisplayName : 'وحدة'}`} />
            {units !== null && Array.isArray(units) && units?.length < 1 && 
                <div className="pt-[80px] text-center">
                  <div className="inline-block w-[188px] h-[188px] !bg-contain !bg-center" style={{background: "url('/assets/images/main-property-icon.svg')"}}></div>
                  <div className="w-full text-center text-grey-900 text-[24px] font-[500] mb-[25px]">لم تقم بإضافة اي {`${property?.propertyType?.unitDisplayName ? property?.propertyType?.unitDisplayName : 'وحدة'}`} حتي الأن</div>
                  <Link className="inline-block min-w-[320px] h-[48px] leading-[48px] rounded-[8px] text-white bg-grey-800 text-[18px] font-[600]" to={`/ar/property/${id}/addUnit`}>{`إضافة ${property?.propertyType?.unitDisplayName ? property?.propertyType?.unitDisplayName : 'وحدة'}`}</Link>
                </div> }
            {units !== null && Array.isArray(units) && units?.length > 0 && 
              <div className="pt-[25px] flex gap-[32px] flex-wrap">
                {units?.map(((unit,index)=>{
                  return <UnitCard key={`property-${index}`} name={unit?.name} city={unit?.addressDetails?.city} onClick={()=>handleClickUnitCard(unit?._id)} />
                }))}
              </div>}
              </div>
            </>}
            </MainContent>
        </PageMain>
        </div>
    </>
  );
};

export default SingleProperty;
