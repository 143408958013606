export default function PropertyCard({parentType=false,image, name, description, propertyType=false, units=0, requests=0, onClick, subProperties=0, commercialData, residentialData, showDescription=true }) {

    let finalImage;
    if(!image) {
        finalImage = "/assets/images/main-property-icon.svg";
    } else {
        finalImage = image;
    }

    let unitDisplayName;

    const subPropertyDisplayName = propertyType?.category === 'commercial' 
                                   ? commercialData?.find(subProperty => subProperty?._id === propertyType?._id)?.children[0]?.name
                                   : residentialData?.find(subProperty => subProperty?._id === propertyType?._id)?.children[0]?.name
    const subPropertyUnitDisplayName = propertyType?.category === 'commercial' 
                                   ? commercialData?.find(subProperty => subProperty?._id === propertyType?._id)?.children[0]?.unitDisplayName
                                   : residentialData?.find(subProperty => subProperty?._id === propertyType?._id)?.children[0]?.unitDisplayName

    if(parentType) {
        unitDisplayName = parentType?.category === 'commercial' 
        ? commercialData?.find(subProperty => subProperty?._id === parentType?._id)?.children[0]?.unitDisplayName
        : residentialData?.find(subProperty => subProperty?._id === parentType?._id)?.children[0]?.unitDisplayName;
    } else {
        unitDisplayName = propertyType?.unitDisplayName;
    }

    return (
        <div className="h-[136px] w-full max-w-[380px] rounded-[8px] overflow-hidden bg-white cursor-pointer px-[8px] py-[16px] relative" onClick={onClick}>
            {/* <div className="inline-block align-middle w-[64px] h-[64px] bg-grey-50 rounded-[8px] overflow-hidden"><span className={`block w-full h-full !bg-center !bg-no-repeat ${ image ? '!bg-contain' : ''}`} style={{background:"url('"+finalImage+"')"}}></span></div> */}
            <div className="inline-block align-middle w-[250px] h-[64px]">
                <div className="whitespace-nowrap overflow-hidden truncate ps-[16px] pe-[10px] text-grey-900 text-[24px] font-[500]">{name}</div>
                {showDescription && <div className="whitespace-nowrap overflow-hidden truncate pt-[2px] ps-[16px] pe-[10px] text-grey-600 text-[14px] font-[400]">{propertyType ? `${propertyType?.category === 'residential' ? 'منشأة سكنية' : 'منشأة تجارية'}  (${propertyType?.name})` : description}</div>}
            </div>
            <div className="w-full h-[40px] absolute bottom-0 left-0 bg-grey-100 flex flex-nowrap justify-between self-stretch items-center p-[8px]">
                <div className="text-grey-600 text-[16px] font-[500]">
                    {propertyType?.allowSubProperty && <><div className="inline-block align-middle pe-[8px]">
                        <span className="inline-block align-middle w-[16px] h-[16px] me-[4px] !bg-center !bg-no-repeat" style={{background:"url('/assets/images/buildings.svg')", backgroundSize: "16px"}}></span>
                        {subProperties?.length} {subPropertyDisplayName ? subPropertyDisplayName : 'منشأت فرعية'}
                    </div></>}
                    <div className={`inline-block align-middle pe-[8px] ${propertyType?.allowSubProperty ? 'ps-[9px] relative' : ''}`}>
                        {propertyType?.allowSubProperty && <span className="block absolute start-0 h-[20px] top-[2px] w-[1px] bg-grey-300"></span>}
                        <span className="inline-block align-middle w-[16px] h-[16px] me-[4px] !bg-center !bg-no-repeat" style={{background:"url('/assets/images/building.svg')", backgroundSize: "16px"}}></span>
                        {units} {subProperties?.length ? subPropertyUnitDisplayName ? subPropertyUnitDisplayName : 'وحدة' : unitDisplayName ? unitDisplayName : 'وحدة'}
                    </div>
                    <div className="inline-block align-middle ps-[9px] relative">
                        <span className="block absolute start-0 h-[20px] top-[2px] w-[1px] bg-grey-300"></span>
                        <span className="inline-block align-middle w-[16px] h-[16px] me-[4px] !bg-center !bg-no-repeat" style={{background:"url('/assets/images/note.svg')", backgroundSize: "16px"}}></span>
                        {requests} طلب
                    </div>
                </div>
                <div className="text-blue text-[16px] font-[500]">
                    تفاصيل
                    <span className="inline-block align-middle w-[16px] h-[16px] ms-[5px] !bg-center !bg-no-repeat" style={{background:"url('/assets/images/arrow-left-blue.svg')"}}></span>
                </div>
            </div>
        </div>
    )

}