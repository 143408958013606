import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import useDocumentTitle from "../../components/helpers/useDocumentTitle";
import PageHeader from "../../components/shared/PageHeader";
import PageMain from "../../containers/shared/PageMain";
import MainContent from "../../containers/shared/MainContent";
import apiClient from "../../components/utils/apiClient";
import useUser from "../../components/helpers/useUser";
import PerventiveVisitsBar from "../../components/PerventiveVisits/PerventiveVisitsBar";
import { FIELDS, PEREVENTIVE_FREQUENCY } from "../../components/utils/constants";
import moment from "moment";
import ModalContainer from "../../containers/shared/ModalContainer";

const PerventiveVisitsHomePage = () => {
    const {t,i18n} = useTranslation();
    useDocumentTitle('الصيانة الوقائية');
    const pageTitle = {title: 'الصيانة الوقائية', subTitle: 'يمكنك تسجيل طلبات تنفذ تلقائيا في ميعاد محدد '};
    const [perventiveVisits, setPerventiveVisits] = useState(null);
    const [user,setUser] = useUser();

    const [showDetails,setShowDetails] = useState(false);
    const [selectedVisit,setSelectedVisit] = useState(null);

    

    const getPerventiveVisits = async () => {
      try {
        const response = await apiClient.get(`/b-preventive-visits`);
        if(response?.data) {
            setPerventiveVisits(response?.data);
        } else {

        }
      } catch(err) {}
    }


    useEffect(()=>{
        getPerventiveVisits();
    },[])

    const handleClickShowDetails = (visit) => {
      setSelectedVisit(visit);
      setShowDetails(true);
    }

    const handleCloseModalAlert = () => {
      setShowDetails(false);
    }
  

  
  return (
    <div>
      <PageHeader pageTitle={pageTitle} isColored />
      <PageMain>
        <div className="absolute top-0 left-0 w-full h-full bg-grey-50 z-0"></div>
        <MainContent>
          <PerventiveVisitsBar perventiveVisitsCount={perventiveVisits?.length} />
          {perventiveVisits ? Array.isArray(perventiveVisits) && perventiveVisits?.length > 0 
          ? <div className="flex flex-wrap gap-[25px] mt-[35px]">
            {perventiveVisits?.map((visit,index)=>{
              return (
              <div key={'visit-'+index} className="w-[45%]">
                <div className="w-full bg-white rounded-[8px] px-[16px] py-[12px] shadow-[0px_8px_24px_0px_rgba(0,0,0,0.08)]">
                  <div className="flex flex-nowrap mb-[15px]">
                    <div className="w-[50%] text-start text-black text-[14px] font-[700] leading-[24px]"><span className="w-[20px] h-[20px] inline-block align-middle bg-blue me-[3px]" style={{maskImage: "url('/assets/images/building.svg')", maskPosition: "center", maskSize: "18px", maskRepeat: "no-repeat"}}></span> الوحدة</div> 
                    <div className="w-[50%] text-end text-darkBlue text-[16px] font-[600]">{visit?.unitName} <span className="text-grey-500">{`(${visit?.propertyName})`}</span></div>  
                  </div>
                  <div className="flex flex-nowrap mb-[15px]">
                    <div className="w-[50%] text-start text-black text-[14px] font-[700] leading-[24px]"><span className="w-[20px] h-[20px] inline-block align-middle bg-blue me-[3px]" style={{maskImage: "url('/assets/images/main-home-icon.svg')", maskPosition: "center", maskSize: "18px", maskRepeat: "no-repeat"}}></span> الخدمات</div> 
                    <div className="w-[50%] text-end text-darkBlue text-[16px] font-[600]">{FIELDS[visit?.field]?.ar}</div>  
                  </div>
                  <div className="flex flex-nowrap mb-[15px]">
                    <div className="w-[50%] text-start text-black text-[14px] font-[700] leading-[24px]"><span className="w-[20px] h-[20px] inline-block align-middle bg-blue me-[3px]" style={{maskImage: "url('/assets/images/calendar.svg')", maskPosition: "center", maskSize: "18px", maskRepeat: "no-repeat"}}></span> الزيارة القادمة في {moment(visit?.startingDate).format('DD-MM-YYYY')}</div> 
                    <div className="w-[50%] text-end text-darkBlue text-[16px] font-[600]">يكرر {PEREVENTIVE_FREQUENCY[visit?.frequency]?.ar}</div>  
                  </div>
                  <div className="flex flex-nowrap mb-[15px]">
                    <div className="w-[50%] text-start text-black text-[14px] font-[700] leading-[24px]"><span className="w-[20px] h-[20px] inline-block align-middle bg-blue me-[3px]" style={{maskImage: "url('/assets/images/note.svg')", maskPosition: "center", maskSize: "18px", maskRepeat: "no-repeat"}}></span> الطلبات</div> 
                    <div className="w-[50%] text-end text-darkBlue text-[16px] font-[600]">0</div>  
                  </div>
                  <div className="flex flex-nowrap mb-[5px] cursor-pointer border-t-[1px] border-t-grey-300 pt-[15px]" onClick={()=>handleClickShowDetails(visit)}>
                    <div className="w-[50%] text-start text-blue text-[16px] font-[700] leading-[24px]">عرض التفاصيل</div> 
                    <div className="w-[50%] text-end text-darkBlue text-[16px] font-[600]"><span className="inline-block w-[16px] h-[16px] align-middle" style={{backgroundImage:"url('/assets/images/arrow-left-blue.svg')", backgroundPosition:"center", backgroundRepeat: "no-repeat"}}></span></div>  
                  </div>
                </div>
              </div>
              )
            })}
            </div>
          : <div className="pt-[100px] text-center">
              <div className="inline-block w-[188px] h-[188px] !bg-contain !bg-center !bg-no-repeat" style={{background: "url('/assets/images/pereventive.svg')"}}></div>
              <div className="w-full text-center text-grey-900 text-[24px] font-[500] mb-[25px]">لم تقم بإضافة اي طلبات صيانة وقائية حتي الأن</div>
              <Link className="inline-block min-w-[320px] h-[48px] leading-[48px] rounded-[8px] text-white bg-grey-800 text-[18px] font-[600]" to={'/ar/preventive-visits/add'}>اضافة طلب صيانة وقائية جديدة</Link>
          </div> : <></> }
        </MainContent>
      </PageMain>



      { showDetails && <ModalContainer heading="تفاصيل زيارة الصيانة الوقائية" handleCloseModal={handleCloseModalAlert}>
      <div className="w-full bg-white">
              <div className="flex flex-nowrap mb-[15px] border-t-[1px] border-t-grey-100 pt-[25px] border-b-[1px] border-b-grey-100 pb-[25px]">
                <div className="w-[50%] text-start text-black text-[14px] font-[700] leading-[24px]"><span className="w-[20px] h-[20px] inline-block align-middle bg-blue me-[3px]" style={{maskImage: "url('/assets/images/building.svg')", maskPosition: "center", maskSize: "18px", maskRepeat: "no-repeat"}}></span> الوحدة</div> 
                <div className="w-[50%] text-end text-darkBlue text-[16px] font-[600]">{selectedVisit?.unitName} <span className="text-grey-500">{`(${selectedVisit?.propertyName})`}</span></div>  
              </div>
              <div className="flex flex-nowrap mb-[15px]">
                <div className="w-[50%] text-start text-black text-[14px] font-[700] leading-[24px]"><span className="w-[20px] h-[20px] inline-block align-middle bg-blue me-[3px]" style={{maskImage: "url('/assets/images/main-home-icon.svg')", maskPosition: "center", maskSize: "18px", maskRepeat: "no-repeat"}}></span> الخدمات</div> 
                <div className="w-[50%] text-end text-darkBlue text-[16px] font-[600]">{FIELDS[selectedVisit?.field]?.ar}</div>  
              </div>
              <div className="flex flex-nowrap mb-[15px] bg-grey-50 rounded-[8px]">
                <div className="w-[50%] text-start text-black text-[14px] font-[700] leading-[51px] ps-[15px]"> خدمة {FIELDS[selectedVisit?.field]?.ar} </div> 
                <div className="w-[50%] text-end text-darkBlue text-[16px] font-[600] leading-[51px] pe-[15px]"> الزيارة القادمة في {moment(selectedVisit?.startingDate).format('DD-MM-YYYY')} / يكرر {PEREVENTIVE_FREQUENCY[selectedVisit?.frequency]?.ar}</div>  
              </div>
              <div className="flex flex-nowrap mb-[15px] border-t-[1px] border-t-grey-100 pt-[25px] border-b-[1px] border-b-grey-100 pb-[25px]">
                <div className="w-[50%] text-start text-black text-[14px] font-[700] leading-[24px]"><span className="w-[20px] h-[20px] inline-block align-middle bg-blue me-[3px]" style={{maskImage: "url('/assets/images/note.svg')", maskPosition: "center", maskSize: "18px", maskRepeat: "no-repeat"}}></span> الطلبات</div> 
                <div className="w-[50%] text-end text-darkBlue text-[16px] font-[600]">0</div>  
              </div>
            </div>
            <div className="text-end">
                <div className="text-grey-400 text-[18px] font-[600] inline-block cursor-pointer select-none me-[25px]" onClick={handleCloseModalAlert}>الغاء</div>
                <div className="inline-block bg-darkBlue text-white px-[8px] text-center text-[18px] font-[600] h-[48px] leading-[48px] w-[300px] rounded-[8px] cursor-pointer">تحديث بيانات الزيارة</div>
            </div>
        </ModalContainer> }


    </div>
  );
};

export default PerventiveVisitsHomePage;
