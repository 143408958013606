import { Link, useNavigate, useParams } from "react-router-dom";

export default function BoxContainer({children, ...props}) {
    
    const navigate = useNavigate();
    let propertyDetails = false;
    if(props?.propertyDetails) {
        propertyDetails = true;
    }

    const handleClickEditUnit = () => {
        if(props?.propertyEditLink) {
            navigate(props?.propertyEditLink);
        }
      }
    
    return (
        <div className="">
        { props?.heading && <p className="text-grey-900 text-[20px] font-[600] leading-[30px] mb-[10px]">{props?.heading}</p>}
        <div style={{maxWidth: `${props?.maxWidth ? props?.maxWidth : '100%' }`}}>
            {propertyDetails ?
            <div className={`h-[56px] leading-[56px] bg-grey-100 text-grey-900 font-[600] text-[20px] px-[16px] rounded-t-[8px] flex`}>
                <div className="w-[50%] text-start">{props?.label}</div>
                <div className="w-[50%] text-end">
                    <div className="cursor-pointer inline-block text-blue text-[18px] font-[600]" onClick={()=>handleClickEditUnit()}>
                        <span className="w-[20px] h-[20px] inline-block align-middle !bg-center !bg-no-repeat me-[4px]" style={{background: "url('/assets/images/edit-2.svg')"}}></span>
                        {props?.editText}
                    </div>
                </div>
            </div>
            :<div className={`h-[56px] leading-[56px] bg-grey-100 text-grey-900 font-[600] text-[16px] px-[16px] rounded-t-[8px]`}>{props?.label}</div>}
            <div className="p-[24px] rounded-b-[8px] border-[1px] border-grey-200 border-t-0 bg-white">
                {children}
            </div>
        </div>
        </div>
    )
}