const firebaseConfig = {
    apiKey: "AIzaSyCr6qJuyOgqLtNjcbVNV09x7eMdgYPCCZI",
    authDomain: "fanniapp-bdcf4.firebaseapp.com",
    databaseURL: "https://fanniapp-bdcf4.firebaseio.com",
    projectId: "fanniapp-bdcf4",
    storageBucket: "fanniapp-bdcf4.appspot.com",
    messagingSenderId: "591978559350",
    appId: "1:591978559350:web:df9a05b421f33c831c6c57",
    measurementId: "G-EXML4S483L"
  };

  const CITIES = {
    Jeddah: {
      ar: 'جدة',
      en: 'Jeddah',
    },
    Mecca: {
      ar: 'مكة',
      en: 'Mecca',
    },
    Riyadh: {
      ar: 'الرياض',
      en: 'Riyadh',
    },
    Medina: {
      ar: 'المدينة المنورة',
      en: 'Medina',
    },
    Dammam: {
      ar: 'الدمام',
      en: 'Dammam',
    },
    'Al Khobar': {
      ar: 'الخبر',
      en: 'Al Khobar',
    },
    Dhahran: {
      ar: 'الظهران',
      en: 'Dhahran',
    },
    'Al Ahsa': {
      ar: 'الأحساء',
      en: 'Al Ahsa',
    },
    Abha: {
      ar: 'أبها',
      en: 'Abha',
    },
    'Khamis Mushait': {
      ar: 'خميس مشيط',
      en: 'Khamis Mushait',
    },
    Taif: {
      ar: 'الطائف',
      en: 'Taif',
    },
    Tabuk: {
      ar: 'تبوك',
      en: 'Tabuk',
    },
    Najran: {
      ar: 'نجران',
      en: 'Najran',
    },
    'Al Bahah': {
      ar: 'الباحة',
      en: 'Al Bahah',
    },
    'Al Lith': {
      ar: 'الليث‎',
      en: 'Al Lith',
    },
    'Al Qassim': {
      ar: 'القاسم',
      en: 'Al Qassim',
    },
    Jazan: {
      ar: 'جيزان',
      en: 'Jazan',
    },
    Rabigh: { ar: 'رابغ', en: 'Rabigh' },
    'Al Jubail': { ar: 'الجبيل', en: 'Al Jubail' },
    Buqayq: { ar: 'بقيق', en: 'Buqayq' },
    'Al-Kharj': { ar: 'الخرج', en: 'Al-Kharj' },
    Hail: { ar: 'حائل', en: 'Hail' },
    buraydh: { ar: 'بريدة', en: 'buraydh' },
    'Hafar Al Batin': { ar: 'حفر الباطن', en: 'Hafar Al Batin' },
    Yanbu: { ar: 'ينبع', en: 'Yanbu' },
    '': { ar: 'لا يوجد مدينة', en: 'city not exist' },
  };

  const FIELDS = {
    plumbing: {
      ar: 'السباكة',
      en: 'Plumbing',
      icon: 'plumbing.svg'
    },
    'air-conditioning': {
      ar: 'التكييفات',
      en: 'Air conditioning',
      icon: 'air-condition.svg'
    },
    carpentry: {
      ar: 'النجارة',
      en: 'Carpentry',
      icon: 'carpenter.svg'
    },
    'satellite-tv': {
      ar: 'الستلايات',
      en: 'Satellite TV',
      icon: 'satellite.svg'
    },
    paved: {
      ar: 'الارضيات',
      en: 'Paved',
      icon: 'paved.svg'
    },
    'home-appliances': {
      ar: 'الأجهزة منزلية',
      en: 'Home appliances',
      icon: 'appliance.svg'
    },
    painting: {
      ar: 'الدهان',
      en: 'Painting',
      icon: 'Painting.svg'
    },
    electrical: {
      ar: 'الكهرباء',
      en: 'Electrical',
      icon: 'Electricity.svg'
    }
  };

  const SERVICES = [
    {
        "field": {
            "code": "home-appliances",
            "name": "اجهزة منزلية"
        },
        "maintenanceListPerDevice": [
            {
                "device": {
                    "code": "automatic-washing-machine",
                    "name": "غسالات اوتاميتك",
                    "url": "https://staging.api.fanni.site/automatic-wash-machine.png"
                },
                "maintenanceList": [
                    {
                        "code": "Electricity access repair",
                        "name": "إصلاح وصول التيار الكهربائي",
                        "cost": "92 ريال",
                        "costAfterDiscount": "92 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "92 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "81 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "69 ريال"
                            }
                        ]
                    },
                    {
                        "code": "water flow repair",
                        "name": "صيانة تدفق الماء",
                        "cost": "127 ريال",
                        "costAfterDiscount": "127 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "127 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "115 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "92 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Electrical seam repair",
                        "name": "صيانة إلتماس كهربائي",
                        "cost": "127 ريال",
                        "costAfterDiscount": "127 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "127 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "115 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "92 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Removing and installing an automatic washing machine door",
                        "name": "فك وتركيب باب ",
                        "cost": "138 ريال",
                        "costAfterDiscount": "138 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Removing and installing the electronic board",
                        "name": "فك وتركيب اللوحة الالكترونية",
                        "cost": "150 ريال",
                        "costAfterDiscount": "150 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "150 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "138 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "115 ريال"
                            }
                        ]
                    },
                    {
                        "code": "water drain repair",
                        "name": "صيانة تصريف الماء",
                        "cost": "150 ريال",
                        "costAfterDiscount": "150 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "150 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "138 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "115 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Removing and installing the stabilizer bar",
                        "name": "فك وتركيب قضيب التعليق التوازن",
                        "cost": "161 ريال",
                        "costAfterDiscount": "161 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "161 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "150 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "127 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Removing and installing the washing machine",
                        "name": "فك وتركيب مكينة الغسيل",
                        "cost": "184 ريال",
                        "costAfterDiscount": "184 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "184 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "173 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "150 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Removing and installing the drying machine",
                        "name": "فك وتركيب مكينة التنشيف",
                        "cost": "219 ريال",
                        "costAfterDiscount": "219 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "219 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "207 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "184 ريال"
                            }
                        ]
                    },
                    {
                        "code": "other",
                        "name": "ما اعرف المشكلة",
                        "cost": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costAfterDiscount": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costPerAmount": []
                    }
                ]
            },
            {
                "device": {
                    "code": "normal-washing-machine",
                    "name": "غسالات عادية",
                    "url": "https://staging.api.fanni.site/normal-wash-machine.png"
                },
                "maintenanceList": [
                    {
                        "code": "water drain repair",
                        "name": "صيانة تصريف الماء",
                        "cost": "104 ريال",
                        "costAfterDiscount": "104 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "104 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "81 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "58 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Electrical seam repair",
                        "name": "صيانة إلتماس كهربائي",
                        "cost": "104 ريال",
                        "costAfterDiscount": "104 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "104 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "92 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "81 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Decoding and installing the timer",
                        "name": "فك وتركيب التايمر",
                        "cost": "115 ريال",
                        "costAfterDiscount": "115 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "115 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "104 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "92 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Dismantling and installing the washing machine",
                        "name": "فك وتركيب مكينة الغسيل",
                        "cost": "144 ريال",
                        "costAfterDiscount": "144 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "144 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "138 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "133 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Dismantling and installing the drying machine",
                        "name": "فك وتركيب مكينة التنشيف",
                        "cost": "156 ريال",
                        "costAfterDiscount": "156 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "156 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "150 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "144 ريال"
                            }
                        ]
                    },
                    {
                        "code": "other",
                        "name": "ما اعرف المشكلة",
                        "cost": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costAfterDiscount": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costPerAmount": []
                    }
                ]
            },
            {
                "device": {
                    "code": "oven",
                    "name": "فرن",
                    "url": "https://staging.api.fanni.site/oven.png"
                },
                "maintenanceList": [
                    {
                        "code": "Change knobs for ovens",
                        "name": "تغيير المفاتيح",
                        "cost": "150 ريال",
                        "costAfterDiscount": "150 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "150 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "138 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "115 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Change the door furnaces for ovens",
                        "name": "تغيير مفصلات الباب",
                        "cost": "184 ريال",
                        "costAfterDiscount": "184 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "184 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "179 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "167 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Clean the oven",
                        "name": "تنظيف ",
                        "cost": "190 ريال",
                        "costAfterDiscount": "190 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "190 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "179 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "161 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Repair of the smuggling of the oven",
                        "name": "صيانة تهريب",
                        "cost": "207 ريال",
                        "costAfterDiscount": "207 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "207 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "196 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "184 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Repair your microwave",
                        "name": "صيانة مايكرويف",
                        "cost": "230 ريال",
                        "costAfterDiscount": "230 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "230 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "219 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "196 ريال"
                            }
                        ]
                    },
                    {
                        "code": "other",
                        "name": "ما اعرف المشكلة",
                        "cost": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costAfterDiscount": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costPerAmount": []
                    }
                ]
            },
            {
                "device": {
                    "code": "refrigerator",
                    "name": "ثلاجة",
                    "url": "https://staging.api.fanni.site/refrigerator.png"
                },
                "maintenanceList": [
                    {
                        "code": "Change Taimer",
                        "name": "تغيير وضبط التايمر",
                        "cost": "115 ريال",
                        "costAfterDiscount": "115 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "115 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "110 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "104 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Change an external fan for refrigerator",
                        "name": "تغيير المروحة الخارجية",
                        "cost": "161 ريال",
                        "costAfterDiscount": "161 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "161 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "150 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "138 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Change an internal fan for refrigerator",
                        "name": "تغيير المروحة الداخلية",
                        "cost": "161 ريال",
                        "costAfterDiscount": "161 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "161 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "150 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "138 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Change the word for refrigerator",
                        "name": "تغيير الدفاية",
                        "cost": "184 ريال",
                        "costAfterDiscount": "184 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "184 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "173 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "161 ريال"
                            }
                        ]
                    },
                    {
                        "code": "External infusion repair for refrigerator without filling freon",
                        "name": "صيانة تهريب خارجي",
                        "cost": "184 ريال",
                        "costAfterDiscount": "184 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "184 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "173 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "161 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Change composer refrigerator without freon",
                        "name": "تغيير كمبروسر ",
                        "cost": "207 ريال",
                        "costAfterDiscount": "207 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "207 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "196 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "184 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Filling of Indian Freon for the refrigerator according to the quantity of the Freon",
                        "name": "تعبئة فريون هندي",
                        "cost": "207 ريال",
                        "costAfterDiscount": "207 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "207 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "196 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "184 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Change composer refrigerator 1/6 - 1/5 - 1/4 - 1/3",
                        "name": "تغيير كمبروسر ثلاجة 1/6 - 1/5 - 1/4 - 1/3",
                        "cost": "207 ريال",
                        "costAfterDiscount": "207 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "207 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "196 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "184 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Change Compression Refrigerator 1/2 - 3/4 - 1",
                        "name": "تغيير كمبروسر ثلاجة 1/2 - 3/4 - 1",
                        "cost": "207 ريال",
                        "costAfterDiscount": "207 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "207 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "196 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "184 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Repair of external smuggling with Chinese Freon Filling",
                        "name": "صيانة تهريب خارجي + تعبئة فريون صيني R12",
                        "cost": "230 ريال",
                        "costAfterDiscount": "230 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "230 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "219 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "207 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Change the sensitive dumb",
                        "name": "تغيير الحساس \"دبفارست\"",
                        "cost": "253 ريال",
                        "costAfterDiscount": "253 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "253 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "110 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "104 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Filling of American Freon",
                        "name": "تعبئة فريون امريكي",
                        "cost": "253 ريال",
                        "costAfterDiscount": "253 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "253 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "242 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "230 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Repair of external smuggling with US Freon Filling R134",
                        "name": "صيانة تهريب خارجي + تعبئة فريون أمريكي R134",
                        "cost": "299 ريال",
                        "costAfterDiscount": "299 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "299 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "288 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "276 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Repair of external smuggling with Indian Freon filling R134",
                        "name": "صيانة تهريب خارجي + تعبئة فريون هندي R134",
                        "cost": "345 ريال",
                        "costAfterDiscount": "345 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "345 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "334 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "322 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Repair of external smuggling with American Freon Filling R12",
                        "name": "صيانة تهريب خارجي + تعبئة فريون أمريكي R12",
                        "cost": "391 ريال",
                        "costAfterDiscount": "391 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "391 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "380 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "368 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Repair of external smuggling with Chinese Freon filling R134",
                        "name": "صيانة تهريب خارجي + تعبئة فريون صيني R134",
                        "cost": "391 ريال",
                        "costAfterDiscount": "391 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "391 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "380 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "368 ريال"
                            }
                        ]
                    },
                    {
                        "code": "other",
                        "name": "ما اعرف المشكلة",
                        "cost": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costAfterDiscount": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costPerAmount": []
                    }
                ]
            },
            {
                "device": {
                    "code": "others",
                    "name": "اخرى",
                    "url": "https://staging.api.fanni.site/other.png"
                },
                "maintenanceList": [
                    {
                        "code": "other",
                        "name": "ما اعرف المشكلة",
                        "cost": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costAfterDiscount": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costPerAmount": []
                    }
                ]
            }
        ]
    },
    {
        "field": {
            "code": "air-conditioning",
            "name": "تكييف"
        },
        "maintenanceListPerDevice": [
            {
                "device": {
                    "code": "central-air-condition",
                    "name": "تكييف مركزي",
                    "url": "https://staging.api.fanni.site/center-air-conditioning.png"
                },
                "maintenanceList": [
                    {
                        "code": "Change place - Mobbat - Capster - Battery",
                        "name": "تغيير مكتن – بوبينة – كوبستر -- بطارية",
                        "cost": "104 ريال",
                        "costAfterDiscount": "104 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "104 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "92 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "81 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Change and install Contackur",
                        "name": "تغيير وتركيب كونتاكتر",
                        "cost": "115 ريال",
                        "costAfterDiscount": "115 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "115 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "104 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "92 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Installation and decomposition of an electronic plate",
                        "name": "تركيب وفك لوحة الكترونية",
                        "cost": "173 ريال",
                        "costAfterDiscount": "173 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "173 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "161 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "150 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Change Dynamo Indoor Machine",
                        "name": "تغيير دينمو مكينه داخلي",
                        "cost": "184 ريال",
                        "costAfterDiscount": "184 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "184 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "173 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "161 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Installation and decomposition of external fan",
                        "name": "تركيب وفك مروحة خارجية",
                        "cost": "207 ريال",
                        "costAfterDiscount": "207 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "207 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "184 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "173 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Change composer 36 - 48 - 60 units",
                        "name": "تغيير كمبروسر 36 – 48 – 60 وحدة",
                        "cost": "207 ريال",
                        "costAfterDiscount": "207 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "207 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "184 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "173 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Change the Split interior crystal",
                        "name": "تغيير بلور داخلي سبليت",
                        "cost": "230 ريال",
                        "costAfterDiscount": "230 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Installation of air conditioner",
                        "name": "تركيب مكيف دولاب",
                        "cost": "403 ريال",
                        "costAfterDiscount": "403 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "403 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "380 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "345 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Installation of cassette air conditioner",
                        "name": "تركيب مكيف كاسيت",
                        "cost": "782 ريال",
                        "costAfterDiscount": "782 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "782 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "748 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "725 ريال"
                            }
                        ]
                    },
                    {
                        "code": "other",
                        "name": "ما اعرف المشكلة",
                        "cost": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costAfterDiscount": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costPerAmount": []
                    }
                ]
            },
            {
                "device": {
                    "code": "window-air-condition",
                    "name": "الشباك",
                    "url": "https://staging.api.fanni.site/window-air-conditioning.png"
                },
                "maintenanceList": [
                    {
                        "code": "Only air conditioner washing - window",
                        "name": "غسيل مكيف",
                        "cost": "78 ريال",
                        "costAfterDiscount": "78 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "78 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "78 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "78 ريال"
                            }
                        ],
                        "offers": [
                            {
                                "count": 3,
                                "cost": "199 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Installation of new window with electricity connection",
                        "name": "تركيب مكيف جديد",
                        "cost": "78 ريال",
                        "costAfterDiscount": "78 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "78 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "78 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "78 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Change place - Mobbat - Capster - Battery",
                        "name": "تغيير بطارية [ بوبينة ]",
                        "cost": "78 ريال",
                        "costAfterDiscount": "78 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "78 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "78 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "78 ريال"
                            }
                        ]
                    },
                    {
                        "code": "The installation and installation of an electric air conditioner inside the house",
                        "name": "فك وتركيب [داخل المنزل]",
                        "cost": "87 ريال",
                        "costAfterDiscount": "87 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "87 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "87 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "87 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Freon smuggling repair with only welding - window",
                        "name": "اصلاح التسريبات",
                        "cost": "97 ريال",
                        "costAfterDiscount": "97 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "97 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "97 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "97 ريال"
                            }
                        ]
                    },
                    {
                        "code": "The laundry is an internal machine with the cleaning of the stirring without decomposition - nets",
                        "name": "تنظيف المجرى",
                        "cost": "97 ريال",
                        "costAfterDiscount": "97 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "97 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "97 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "97 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Freon smuggling repair with only welding - window",
                        "name": "اصلاح تهريب الفريون مع اللحام",
                        "cost": "113 ريال",
                        "costAfterDiscount": "113 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "113 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "113 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "113 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Demo change",
                        "name": "تغيير دينمو",
                        "cost": "113 ريال",
                        "costAfterDiscount": "113 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "113 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "113 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "113 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Air Conditioner with Freon - Window",
                        "name": "تنظيف + تعبئة فريون",
                        "cost": "147 ريال",
                        "costAfterDiscount": "147 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "147 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "147 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "147 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Change composer 36 - 48 - 60 units",
                        "name": "تغيير كمبروسر",
                        "cost": "185 ريال",
                        "costAfterDiscount": "185 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "185 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "185 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "185 ريال"
                            }
                        ]
                    },
                    {
                        "code": "other",
                        "name": "ما اعرف المشكلة",
                        "cost": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costAfterDiscount": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costPerAmount": []
                    }
                ]
            },
            {
                "device": {
                    "code": "split-air-condition",
                    "name": "السبيليت",
                    "url": "https://staging.api.fanni.site/split-air-conditioning.png"
                },
                "maintenanceList": [
                    {
                        "code": "Change place - Mobbat - Capster - Battery",
                        "name": "تغيير بطارية [بوبينة]",
                        "cost": "97 ريال",
                        "costAfterDiscount": "97 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "97 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "97 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "97 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Washing and outside without decoding - Split",
                        "name": "غسيل مكيف [ تنظيف الوحدة الداخلية والخارجية ]",
                        "cost": "127 ريال",
                        "costAfterDiscount": "127 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "127 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "127 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "127 ريال"
                            }
                        ],
                        "offers": [
                            {
                                "count": 4,
                                "cost": "399 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Leakage - Indoor - Split with cleaning filters",
                        "name": "اصلاح التسريبات",
                        "cost": "128 ريال",
                        "costAfterDiscount": "128 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "128 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "128 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "128 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Installation and decomposition of an electronic plate - Split",
                        "name": "فك و تركيب لوحة الكترونية",
                        "cost": "146 ريال",
                        "costAfterDiscount": "146 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "146 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "146 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "146 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Change Dynamo External Machine",
                        "name": "تغيير دينمو [خارجي]",
                        "cost": "146 ريال",
                        "costAfterDiscount": "146 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "146 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "146 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "146 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Installation and decomposition fan - Split",
                        "name": "فك و تركيب مروحة خارجية ",
                        "cost": "156 ريال",
                        "costAfterDiscount": "156 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "156 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "156 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "156 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Change Dynamo Indoor Machine",
                        "name": "تغيير دينمو [داخلي]",
                        "cost": "185 ريال",
                        "costAfterDiscount": "185 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "185 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "185 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "185 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Change composer 36 - 48 - 60 units",
                        "name": "تغيير كمبروسر",
                        "cost": "185 ريال",
                        "costAfterDiscount": "185 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "185 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "185 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "185 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Washing air conditioner and external machine with freon",
                        "name": "تنظيف جميع الوحدات + تعبئة فريون",
                        "cost": "244 ريال",
                        "costAfterDiscount": "244 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "244 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "244 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "244 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Install new spice without internal extension",
                        "name": "تركيب مكيف جديد",
                        "cost": "244 ريال",
                        "costAfterDiscount": "244 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "244 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "244 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "244 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Installation and decomposition and transportation in the house",
                        "name": "فك وتركيب  [داخل المنزل]",
                        "cost": "244 ريال",
                        "costAfterDiscount": "244 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "244 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "244 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "244 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Installation and decomposition and transportation of an air conditioner outside the house",
                        "name": "فك وتركيب  [خارج المنزل]",
                        "cost": "361 ريال",
                        "costAfterDiscount": "361 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "361 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "361 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "361 ريال"
                            }
                        ]
                    },
                    {
                        "code": "other",
                        "name": "ما اعرف المشكلة",
                        "cost": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costAfterDiscount": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costPerAmount": []
                    }
                ]
            },
            {
                "device": {
                    "code": "others",
                    "name": "اخرى",
                    "url": "https://staging.api.fanni.site/other.png"
                },
                "maintenanceList": [
                    {
                        "code": "other",
                        "name": "ما اعرف المشكلة",
                        "cost": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costAfterDiscount": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costPerAmount": []
                    }
                ]
            }
        ]
    },
    {
        "field": {
            "code": "plumbing",
            "name": "سباكة"
        },
        "maintenanceListPerDevice": [
            {
                "device": {
                    "code": "Installation",
                    "name": "أهم الخدمات",
                    "url": "https://staging.api.fanni.site/installation.png"
                },
                "maintenanceList": [
                    {
                        "code": "replaceOneSprayerForToilet",
                        "name": "تغيير شطاف",
                        "cost": "29 ريال",
                        "costAfterDiscount": "29 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "29 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "29 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "29 ريال"
                            }
                        ],
                        "offers": [
                            {
                                "count": 3,
                                "cost": "69 ريال"
                            }
                        ]
                    },
                    {
                        "code": "installOneSprinklerShowerHead",
                        "name": "تركيب سماعة دش",
                        "cost": "30 ريال",
                        "costAfterDiscount": "30 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "30 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "30 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "30 ريال"
                            }
                        ]
                    },
                    {
                        "code": "drainageBlockage",
                        "name": "تسليك انسداد التصريف",
                        "cost": "97 ريال",
                        "costAfterDiscount": "97 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "97 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "97 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "97 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Tsic blockage kitchen basin",
                        "name": "تسليك انسداد حوض المطبخ",
                        "cost": "97 ريال",
                        "costAfterDiscount": "97 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "97 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "97 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "97 ريال"
                            }
                        ]
                    },
                    {
                        "code": "REPLACE_HIDDEN_HEATER",
                        "name": "تغيير سخان مخفي",
                        "cost": "146 ريال",
                        "costAfterDiscount": "146 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "146 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "146 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "146 ريال"
                            }
                        ]
                    }
                ]
            },
            {
                "device": {
                    "code": "change",
                    "name": "تركيب و تأسيس",
                    "url": "https://staging.api.fanni.site/fix.png"
                },
                "maintenanceList": [
                    {
                        "code": "installUniversalToiletSeat",
                        "name": "تركيب كرسي افرنجي",
                        "cost": "78 ريال",
                        "costAfterDiscount": "78 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "78 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "78 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "78 ريال"
                            }
                        ]
                    },
                    {
                        "code": "installHeater",
                        "name": "تركيب سخان",
                        "cost": "97 ريال",
                        "costAfterDiscount": "97 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "97 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "97 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "97 ريال"
                            }
                        ]
                    },
                    {
                        "code": "installArabicFlush",
                        "name": "تركيب سيفون عربي",
                        "cost": "97 ريال",
                        "costAfterDiscount": "97 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "97 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "97 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "97 ريال"
                            }
                        ]
                    },
                    {
                        "code": "installWashbasin",
                        "name": "تركيب مغسلة",
                        "cost": "127 ريال",
                        "costAfterDiscount": "127 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "127 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "127 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "127 ريال"
                            }
                        ]
                    },
                    {
                        "code": "installOrReplaceHiddenHeater",
                        "name": "تركيب سخان مخفي",
                        "cost": "146 ريال",
                        "costAfterDiscount": "146 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "146 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "146 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "146 ريال"
                            }
                        ]
                    },
                    {
                        "code": "installWashbasinCabinet",
                        "name": "تركيب مغسلة دولاب",
                        "cost": "156 ريال",
                        "costAfterDiscount": "156 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "156 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "156 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "156 ريال"
                            }
                        ]
                    },
                    {
                        "code": "installShowerRoom",
                        "name": "تركيب كابينة استحمام [شاور]",
                        "cost": "293 ريال",
                        "costAfterDiscount": "293 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "293 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "293 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "293 ريال"
                            }
                        ]
                    }
                ]
            },
            {
                "device": {
                    "code": "others",
                    "name": "اخرى",
                    "url": "https://staging.api.fanni.site/other.png"
                },
                "maintenanceList": [
                    {
                        "code": "replaceOneStopcockWithHose",
                        "name": "تغيير محبس زاوية مع لي",
                        "cost": "39 ريال",
                        "costAfterDiscount": "39 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "39 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "39 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "39 ريال"
                            }
                        ]
                    },
                    {
                        "code": "washbasinWasteTrap",
                        "name": "تغيير هراب عادي",
                        "cost": "39 ريال",
                        "costAfterDiscount": "39 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "39 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "39 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "39 ريال"
                            }
                        ]
                    },
                    {
                        "code": "koreanWashbasinWasteTrap",
                        "name": "تغيير هراب كوري",
                        "cost": "39 ريال",
                        "costAfterDiscount": "39 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "39 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "39 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "39 ريال"
                            }
                        ]
                    },
                    {
                        "code": "replaceAUniversalToiletSeatCover",
                        "name": "تغيير غطاء كرسي افرنجي",
                        "cost": "44 ريال",
                        "costAfterDiscount": "44 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "44 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "44 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "44 ريال"
                            }
                        ]
                    },
                    {
                        "code": "replaceFloatValve",
                        "name": "تغيير عوامة الخزان",
                        "cost": "78 ريال",
                        "costAfterDiscount": "78 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "78 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "78 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "78 ريال"
                            }
                        ]
                    },
                    {
                        "code": "replaceElectricHeater",
                        "name": "تغيير قلب سخان عادي",
                        "cost": "79 ريال",
                        "costAfterDiscount": "79 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "79 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "79 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "79 ريال"
                            }
                        ]
                    },
                    {
                        "code": "installOrReplaceDynamo",
                        "name": "تركيب او تغيير دينمو",
                        "cost": "146 ريال",
                        "costAfterDiscount": "146 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "146 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "146 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "146 ريال"
                            }
                        ]
                    },
                    {
                        "code": "RemoveUniversalToiletSeatAndInstallToAnotherPlace",
                        "name": "فك وتركيب كرسي افرنجي جديد",
                        "cost": "185 ريال",
                        "costAfterDiscount": "185 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "185 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "185 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "185 ريال"
                            }
                        ]
                    },
                    {
                        "code": "replaceArabicToiletSeat",
                        "name": "فك وتركيب كرسي عربي",
                        "cost": "195 ريال",
                        "costAfterDiscount": "195 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "195 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "195 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "195 ريال"
                            }
                        ]
                    },
                    {
                        "code": "other",
                        "name": "ما اعرف المشكلة",
                        "cost": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costAfterDiscount": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costPerAmount": []
                    }
                ]
            }
        ]
    },
    {
        "field": {
            "code": "satellite-tv",
            "name": "ستالايت"
        },
        "maintenanceListPerDevice": [
            {
                "device": {
                    "code": "programming",
                    "name": "برمجة",
                    "url": "https://staging.api.fanni.site/programming.png"
                },
                "maintenanceList": [
                    {
                        "code": "Internal programming",
                        "name": "برمجة داخلية",
                        "cost": "92 ريال",
                        "costAfterDiscount": "92 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "92 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "69 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "58 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Shower programming (weight dish)",
                        "name": "برمجة دش (وزن صحن)",
                        "cost": "104 ريال",
                        "costAfterDiscount": "104 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "104 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "81 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "58 ريال"
                            }
                        ]
                    },
                    {
                        "code": "A description of the weight of a dish",
                        "name": "برمجة رسيفر + وزن صحن",
                        "cost": "127 ريال",
                        "costAfterDiscount": "127 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "127 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "115 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "92 ريال"
                            }
                        ]
                    },
                    {
                        "code": "other",
                        "name": "ما اعرف المشكلة",
                        "cost": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costAfterDiscount": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costPerAmount": []
                    }
                ]
            },
            {
                "device": {
                    "code": "Installation",
                    "name": "تركيب",
                    "url": "https://staging.api.fanni.site/installation.png"
                },
                "maintenanceList": [
                    {
                        "code": "Installation of one line",
                        "name": "تركيب رأس خط واحد",
                        "cost": "81 ريال",
                        "costAfterDiscount": "81 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "81 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "58 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "35 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Installation of two lines",
                        "name": "تركيب رأس خطين",
                        "cost": "127 ريال",
                        "costAfterDiscount": "127 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "127 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "104 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "81 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Install full dish with weight and programming",
                        "name": "تركيب صحن + وزن وبرمجة",
                        "cost": "173 ريال",
                        "costAfterDiscount": "173 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "173 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "150 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "127 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Installation of heads 4 lines",
                        "name": "تركيب رأس 4 خطوط",
                        "cost": "184 ريال",
                        "costAfterDiscount": "184 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "184 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "161 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "138 ريال"
                            }
                        ]
                    },
                    {
                        "code": "other",
                        "name": "ما اعرف المشكلة",
                        "cost": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costAfterDiscount": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costPerAmount": []
                    }
                ]
            },
            {
                "device": {
                    "code": "others",
                    "name": "اخرى",
                    "url": "https://staging.api.fanni.site/other.png"
                },
                "maintenanceList": [
                    {
                        "code": "New flood outside wall",
                        "name": "تسليك جديد \"خارج الجدار\"",
                        "cost": "92 ريال",
                        "costAfterDiscount": "92 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "92 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "81 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "58 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Commentary TV on the wall",
                        "name": "تعليق تلفزيون على الجدار \"صغير\"",
                        "cost": "92 ريال",
                        "costAfterDiscount": "92 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "92 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "81 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "58 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Suspension a large TV on the wall",
                        "name": "تعليق تلفزيون على الجدار \"كبير\"",
                        "cost": "115 ريال",
                        "costAfterDiscount": "115 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "115 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "104 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "81 ريال"
                            }
                        ]
                    },
                    {
                        "code": "New Tsic inside the wall",
                        "name": "تسليك جديد \"داخل الجدار\"",
                        "cost": "184 ريال",
                        "costAfterDiscount": "184 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "184 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "161 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "138 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Move a shower to another place",
                        "name": "نقل دش إلى مكان آخر",
                        "cost": "184 ريال",
                        "costAfterDiscount": "184 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "184 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "161 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "138 ريال"
                            }
                        ]
                    },
                    {
                        "code": "other",
                        "name": "ما اعرف المشكلة",
                        "cost": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costAfterDiscount": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costPerAmount": []
                    }
                ]
            }
        ]
    },
    {
        "field": {
            "code": "electrical",
            "name": "كهرباء"
        },
        "maintenanceListPerDevice": [
            {
                "device": {
                    "code": "Installation",
                    "name": "أهم الخدمات",
                    "url": "https://staging.api.fanni.site/installation.png"
                },
                "maintenanceList": [
                    {
                        "code": "replaceOneSpotlightSmallSize",
                        "name": "تغيير سبوت لايت",
                        "cost": "19 ريال",
                        "costAfterDiscount": "19 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "19 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "19 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "19 ريال"
                            }
                        ],
                        "offers": [
                            {
                                "count": 5,
                                "cost": "59 ريال"
                            }
                        ]
                    },
                    {
                        "code": "installSwitchesInTheGround",
                        "name": "تغيير فيش",
                        "cost": "29 ريال",
                        "costAfterDiscount": "29 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "29 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "29 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "29 ريال"
                            }
                        ],
                        "offers": [
                            {
                                "count": 5,
                                "cost": "99 ريال"
                            }
                        ]
                    },
                    {
                        "code": "installOneSwitch(AC-Stove-Heater)",
                        "name": "تركيب مفتاح مكيف أو سخان",
                        "cost": "39 ريال",
                        "costAfterDiscount": "39 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "39 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "39 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "39 ريال"
                            }
                        ]
                    },
                    {
                        "code": "installExhaustFan",
                        "name": "تركيب مروحة شفاط",
                        "cost": "48 ريال",
                        "costAfterDiscount": "48 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "48 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "48 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "48 ريال"
                            }
                        ],
                        "offers": [
                            {
                                "count": 5,
                                "cost": "119 ريال"
                            }
                        ]
                    },
                    {
                        "code": "installBathroomHeater",
                        "name": "تركيب سخان",
                        "cost": "97 ريال",
                        "costAfterDiscount": "97 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "97 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "97 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "97 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Repair of Intercom",
                        "name": "صيانة إنتركوم",
                        "cost": "127 ريال",
                        "costAfterDiscount": "127 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "127 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "127 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "127 ريال"
                            }
                        ]
                    }
                ]
            },
            {
                "device": {
                    "code": "change",
                    "name": "تركيب و تأسيس",
                    "url": "https://staging.api.fanni.site/fix.png"
                },
                "maintenanceList": [
                    {
                        "code": "installOneSpotlightWithHolesSmallSize",
                        "name": "تركيب سبوت لايت مع تخريم [الحجم الصغير]",
                        "cost": "29 ريال",
                        "costAfterDiscount": "29 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "29 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "29 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "29 ريال"
                            }
                        ]
                    },
                    {
                        "code": "installOneSpotlightWithHolesLargeSize",
                        "name": "تركيب سبوت لايت مع تخريم [حجم كبير]",
                        "cost": "39 ريال",
                        "costAfterDiscount": "39 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "39 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "39 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "39 ريال"
                            }
                        ]
                    },
                    {
                        "code": "installOneElectronicSwitches",
                        "name": "تركيب فيش كهرباء",
                        "cost": "39 ريال",
                        "costAfterDiscount": "39 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "39 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "39 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "39 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Install normal bell",
                        "name": "تركيب جرس",
                        "cost": "58 ريال",
                        "costAfterDiscount": "58 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "58 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "58 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "58 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Install your new intercom",
                        "name": "تركيب انتركم جديد",
                        "cost": "195 ريال",
                        "costAfterDiscount": "195 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "195 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "195 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "195 ريال"
                            }
                        ]
                    }
                ]
            },
            {
                "device": {
                    "code": "others",
                    "name": "اخرى",
                    "url": "https://staging.api.fanni.site/other.png"
                },
                "maintenanceList": [
                    {
                        "code": "replaceRoofLights",
                        "name": "تغيير لمبات السطح",
                        "cost": "39 ريال",
                        "costAfterDiscount": "39 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "39 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "39 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "39 ريال"
                            }
                        ]
                    },
                    {
                        "code": "installOneHiddenLedStrips",
                        "name": "تركيب شريط ليد مخفي [بالمتر]",
                        "cost": "39 ريال",
                        "costAfterDiscount": "39 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "39 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "39 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "39 ريال"
                            }
                        ]
                    },
                    {
                        "code": "installElectricMeter",
                        "name": "تغيير مفتاح رئيسي لطبلون الكهرباء",
                        "cost": "58 ريال",
                        "costAfterDiscount": "58 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "58 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "58 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "58 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Candle (400)",
                        "name": "[LED ٤٠٠ ] تغيير شمعة عامود",
                        "cost": "68 ريال",
                        "costAfterDiscount": "68 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "68 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "68 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "68 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Wall Candle - 400 Scouts",
                        "name": " تركيب شمعة جداري [٤٠٠ كشاف]",
                        "cost": "87 ريال",
                        "costAfterDiscount": "87 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "87 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "87 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "87 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Install the smart lock",
                        "name": "تثبيت القفل الذكي للباب",
                        "cost": "136 ريال",
                        "costAfterDiscount": "136 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "136 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "136 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "136 ريال"
                            }
                        ]
                    },
                    {
                        "code": "replaceSubKeyOfElectricMeter",
                        "name": "تغيير مفتاح فرعي لطبلون الكهرباء",
                        "cost": "146 ريال",
                        "costAfterDiscount": "146 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "146 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "146 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "146 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Extension of electricity for washing machine or clothing - 6 mm",
                        "name": "تمديد كهرباء لغسالة أو نشافة ملابس",
                        "cost": "156 ريال",
                        "costAfterDiscount": "156 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "156 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "156 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "156 ريال"
                            }
                        ]
                    },
                    {
                        "code": "electricMeterPrimaryKey",
                        "name": "تركيب طبلون كهرباء كامل داخلي",
                        "cost": "420 ريال",
                        "costAfterDiscount": "420 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "420 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "420 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "420 ريال"
                            }
                        ]
                    },
                    {
                        "code": "other",
                        "name": "ما اعرف المشكلة",
                        "cost": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costAfterDiscount": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costPerAmount": []
                    }
                ]
            }
        ]
    },
    {
        "field": {
            "code": "carpentry",
            "name": "نجارة"
        },
        "maintenanceListPerDevice": [
            {
                "device": {
                    "code": "Installation",
                    "name": "تركيب",
                    "url": "https://staging.api.fanni.site/installation.png"
                },
                "maintenanceList": [
                    {
                        "code": "Installation One panel",
                        "name": "تركيب لوحة ",
                        "cost": "58 ريال",
                        "costAfterDiscount": "58 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "58 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "41 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "23 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Installation of air conditioner frame",
                        "name": "تركيب برواز مكيف",
                        "cost": "69 ريال",
                        "costAfterDiscount": "69 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "69 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "52 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "35 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Installation of mirrors",
                        "name": "تركيب مرايا",
                        "cost": "75 ريال",
                        "costAfterDiscount": "75 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "75 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "41 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "23 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Installation of door brochure",
                        "name": "تركيب برواز باب",
                        "cost": "98 ريال",
                        "costAfterDiscount": "98 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "98 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "87 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "58 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Installation of hairstyle",
                        "name": "تركيب تسريحة",
                        "cost": "144 ريال",
                        "costAfterDiscount": "144 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "144 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "127 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "110 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Installation of IKEA wheel - small",
                        "name": "تركيب دولاب ايكيا \"صغير\"",
                        "cost": "179 ريال",
                        "costAfterDiscount": "179 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "179 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "161 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "144 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Installation of a national wheel",
                        "name": "تركيب دولاب وطني",
                        "cost": "242 ريال",
                        "costAfterDiscount": "242 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "242 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "225 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "202 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Install new office",
                        "name": "تركيب مكتب جديد",
                        "cost": "242 ريال",
                        "costAfterDiscount": "242 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "242 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "225 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "202 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Installation of IKEA wheel - middle",
                        "name": "تركيب دولاب ايكيا  \"وسط\"",
                        "cost": "259 ريال",
                        "costAfterDiscount": "259 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "259 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "242 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "225 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Installation of a Saudi door or a full persistent",
                        "name": "تركيب باب سعودي أو مقنو كامل",
                        "cost": "271 ريال",
                        "costAfterDiscount": "271 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "271 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "242 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "219 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Installation of IKEA - Large",
                        "name": "تركيب دولاب ايكيا \"كبير\"",
                        "cost": "345 ريال",
                        "costAfterDiscount": "345 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "345 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "322 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "299 ريال"
                            }
                        ]
                    },
                    {
                        "code": "other",
                        "name": "ما اعرف المشكلة",
                        "cost": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costAfterDiscount": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costPerAmount": []
                    }
                ]
            },
            {
                "device": {
                    "code": "Installation change",
                    "name": "فك و تركيب",
                    "url": "https://staging.api.fanni.site/remove-and-installation.png"
                },
                "maintenanceList": [
                    {
                        "code": "Decoding and installing a shelf",
                        "name": "فك و تركيب رف",
                        "cost": "81 ريال",
                        "costAfterDiscount": "81 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "81 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "46 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "23 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Unpack and install curtain",
                        "name": "فك و تركيب ستارة",
                        "cost": "115 ريال",
                        "costAfterDiscount": "115 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "115 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "104 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "92 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Decode",
                        "name": "فك مكتب",
                        "cost": "161 ريال",
                        "costAfterDiscount": "161 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "161 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "138 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "115 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Unpack and install curtain 3 and Joe - big",
                        "name": "فك و تركيب ستارة 3 وجوه \"كبيرة\"",
                        "cost": "173 ريال",
                        "costAfterDiscount": "173 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "173 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "161 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "138 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Decoding IKEA - National",
                        "name": "فك دولاب ايكيا",
                        "cost": "184 ريال",
                        "costAfterDiscount": "184 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "184 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "173 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "161 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Unpack and install the door",
                        "name": "فك و تركيب باب",
                        "cost": "207 ريال",
                        "costAfterDiscount": "207 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "207 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "196 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "173 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Decoding and installation bed",
                        "name": "فك و تركيب سرير",
                        "cost": "207 ريال",
                        "costAfterDiscount": "207 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "207 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "196 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "184 ريال"
                            }
                        ]
                    },
                    {
                        "code": "other",
                        "name": "ما اعرف المشكلة",
                        "cost": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costAfterDiscount": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costPerAmount": []
                    }
                ]
            },
            {
                "device": {
                    "code": "others",
                    "name": "اخرى",
                    "url": "https://staging.api.fanni.site/other.png"
                },
                "maintenanceList": [
                    {
                        "code": "Mounting Kellon",
                        "name": "تركيب كيلون",
                        "cost": "81 ريال",
                        "costAfterDiscount": "81 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "81 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "69 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "58 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Cut the door",
                        "name": "قص باب",
                        "cost": "104 ريال",
                        "costAfterDiscount": "104 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "104 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "81 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "58 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Open a closed door",
                        "name": "فتح باب مغلق",
                        "cost": "115 ريال",
                        "costAfterDiscount": "115 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "115 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "92 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "81 ريال"
                            }
                        ]
                    },
                    {
                        "code": "Door repair - abrasion, weight, installation lashes",
                        "name": "صيانة باب - كشط / وزن/ تركيب جلدة",
                        "cost": "150 ريال",
                        "costAfterDiscount": "150 ريال",
                        "costPerAmount": [
                            {
                                "count": 1,
                                "cost": "150 ريال"
                            },
                            {
                                "count": 2,
                                "cost": "127 ريال"
                            },
                            {
                                "count": 3,
                                "cost": "92 ريال"
                            }
                        ]
                    },
                    {
                        "code": "other",
                        "name": "ما اعرف المشكلة",
                        "cost": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costAfterDiscount": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costPerAmount": []
                    }
                ]
            }
        ]
    },
    {
        "field": {
            "code": "mobiles",
            "name": "صيانة جوالات"
        },
        "maintenanceListPerDevice": [
            {
                "device": {
                    "code": "fix",
                    "name": "اصلاح",
                    "url": "https://staging.api.fanni.site/fix.png"
                },
                "maintenanceList": [
                    {
                        "code": "Change the iPhone 5G screen",
                        "name": "تغيير شاشة IPhone 5G",
                        "cost": "173 ريال",
                        "costAfterDiscount": "173 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Change screen i Phone 5s",
                        "name": "تغيير شاشة IPhone 5S",
                        "cost": "173 ريال",
                        "costAfterDiscount": "173 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Change screen i phone 5c",
                        "name": "تغيير شاشة IPhone 5C",
                        "cost": "173 ريال",
                        "costAfterDiscount": "173 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Change the iPhone 6G screen",
                        "name": "تغيير شاشة IPhone 6G",
                        "cost": "173 ريال",
                        "costAfterDiscount": "173 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Change screen i phone +6",
                        "name": "تغيير شاشة IPhone +6",
                        "cost": "173 ريال",
                        "costAfterDiscount": "173 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Change i Phone 6s",
                        "name": "تغيير شاشة IPhone 6S",
                        "cost": "173 ريال",
                        "costAfterDiscount": "173 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Change screen i phone + 6s",
                        "name": "تغيير شاشة IPhone +6S",
                        "cost": "173 ريال",
                        "costAfterDiscount": "173 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "IPhone 5 - 6 - 6 S - 6 S Plus",
                        "name": "صيانة المايكرفون ايفون 5 - 6 - 6اس - 6اس بلس",
                        "cost": "173 ريال",
                        "costAfterDiscount": "173 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "IPhone 7 - 7 Plus - 8 - 8 Plus - X",
                        "name": "صيانة المايكرفون \"ايفون 7 - 7 بلس - 8 - 8 بلس - x\"",
                        "cost": "173 ريال",
                        "costAfterDiscount": "173 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Headset for iPhone 5 - 6 - 6 S - 6 S Plus",
                        "name": "صيانة مشاكل مكبر الصوت \"ايفون 5 - 6 - 6 اس - 6 اس بلس\"",
                        "cost": "173 ريال",
                        "costAfterDiscount": "173 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Problem with Mobile Shipping iPhone 5 - 6 - 6 Plus - 6 S - 6 S Plus",
                        "name": "صيانة مدخل الشحن \"ايفون 5 - 6 - 6بلس - 6 اس - 6 اس بلس\"",
                        "cost": "173 ريال",
                        "costAfterDiscount": "173 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "A problem in the battery from iPhone 5 to 6 S Plus",
                        "name": "صيانة البطارية \"ايفون 5 الى  6S بلس\"",
                        "cost": "173 ريال",
                        "costAfterDiscount": "173 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Software Software",
                        "name": "صيانة مشاكل البرمجة",
                        "cost": "173 ريال",
                        "costAfterDiscount": "173 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Transportation is working only hand",
                        "name": "موصلات وشغل يد فقط",
                        "cost": "173 ريال",
                        "costAfterDiscount": "173 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Change screen i phone 7g",
                        "name": "تغيير شاشة IPhone 7G",
                        "cost": "230 ريال",
                        "costAfterDiscount": "230 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Change screen i phone +7",
                        "name": "تغيير شاشة IPhone +7",
                        "cost": "230 ريال",
                        "costAfterDiscount": "230 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Change the iPhone 8G screen",
                        "name": "تغيير شاشة IPhone 8G",
                        "cost": "230 ريال",
                        "costAfterDiscount": "230 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Change screen i phone +8",
                        "name": "تغيير شاشة IPhone +8",
                        "cost": "230 ريال",
                        "costAfterDiscount": "230 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Headset - iPhone 8 - 8 Plus - X",
                        "name": "صيانة مشاكل مكبر الصوت \"ايفون 8 - 8 بلس - x\"",
                        "cost": "230 ريال",
                        "costAfterDiscount": "230 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Mobile Shipping Problem iPhone 7 - 7 Plus - 8 - 8 Plus - X",
                        "name": "صيانة مدخل الشحن \"ايفون 7 - 7 بلس - 8 - 8 بلس - X\"",
                        "cost": "230 ريال",
                        "costAfterDiscount": "230 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "A problem in the battery from iPhone 7 to iPhone x",
                        "name": "صيانة البطارية \"ايفون 7 الى X\"",
                        "cost": "230 ريال",
                        "costAfterDiscount": "230 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Problem with iPhone 5 to 6 S",
                        "name": "صيانة الكاميرا \"ايفون 5 الى 6S\"",
                        "cost": "230 ريال",
                        "costAfterDiscount": "230 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Problem with iPhone 7 to X",
                        "name": "صيانة الكاميرا \"ايفون 7 الى x\"",
                        "cost": "230 ريال",
                        "costAfterDiscount": "230 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Problem in side buttons, audio",
                        "name": "صيانة الأزرار الجانبية والصوت",
                        "cost": "230 ريال",
                        "costAfterDiscount": "230 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Water scares",
                        "name": "صيانة خرابات الماء",
                        "cost": "230 ريال",
                        "costAfterDiscount": "230 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Change your phone screen x",
                        "name": "تغيير شاشة IPhone X",
                        "cost": "288 ريال",
                        "costAfterDiscount": "288 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Change the i phone XS screen",
                        "name": "تغيير شاشة IPhone Xs",
                        "cost": "288 ريال",
                        "costAfterDiscount": "288 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Change screen i phone XR",
                        "name": "تغيير شاشة IPhone Xr",
                        "cost": "288 ريال",
                        "costAfterDiscount": "288 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Change the iPhone XSMAX screen",
                        "name": "تغيير شاشة IPhone xsmax",
                        "cost": "288 ريال",
                        "costAfterDiscount": "288 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Network problems",
                        "name": "صيانة مشاكل الشبكة",
                        "cost": "288 ريال",
                        "costAfterDiscount": "288 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Mobile does not work can not be operated",
                        "name": "الجوال لا يمكن تشغيله",
                        "cost": "288 ريال",
                        "costAfterDiscount": "288 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Reference problem",
                        "name": "مشكلة في الاشارة",
                        "cost": "288 ريال",
                        "costAfterDiscount": "288 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "I do not shake",
                        "name": "صيانة الاهتزاز",
                        "cost": "288 ريال",
                        "costAfterDiscount": "288 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Touch problem",
                        "name": "صيانة اللمس",
                        "cost": "322 ريال",
                        "costAfterDiscount": "322 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Headset for iPhone 7 - 7 Plus",
                        "name": "صيانة مشاكل مكبر الصوت \"ايفون 7 - 7 بلس\"",
                        "cost": "403 ريال",
                        "costAfterDiscount": "403 ريال",
                        "costPerAmount": []
                    },
                    {
                        "code": "Samsung Huawei Sony Tab HTC",
                        "name": "سامسونج هواوي سوني تاب HTC",
                        "cost": "NaN حسب",
                        "costAfterDiscount": "NaN حسب",
                        "costPerAmount": []
                    },
                    {
                        "code": "other",
                        "name": "ما اعرف المشكلة",
                        "cost": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costAfterDiscount": "يتم تحديد المشكلة وتزوديك بالقيمة بعد الكشف والزيارة",
                        "costPerAmount": []
                    }
                ]
            }
        ]
    },
    {
        "field": {
            "code": "painting",
            "name": "دهان"
        },
        "maintenanceListPerDevice": [
            {
                "device": null,
                "maintenanceList": [
                    {
                        "code": "price-will-be-determined-after-inspection",
                        "name": "يتم تحديد السعر بعد الكشف والزيارة",
                        "cost": "NaN تحديد",
                        "costAfterDiscount": "NaN تحديد"
                    }
                ]
            }
        ]
    },
    {
        "field": {
            "code": "furniture-transportation",
            "name": "نقل اثاث"
        },
        "maintenanceListPerDevice": [
            {
                "device": null,
                "maintenanceList": [
                    {
                        "code": "price-will-be-determined-after-inspection",
                        "name": "يتم تحديد السعر بعد الكشف والزيارة",
                        "cost": "NaN تحديد",
                        "costAfterDiscount": "NaN تحديد"
                    }
                ]
            }
        ]
    },
    {
        "field": {
            "code": "cleaning",
            "name": "تنظيف ومكافحة حشرات"
        },
        "maintenanceListPerDevice": [
            {
                "device": null,
                "maintenanceList": [
                    {
                        "code": "price-will-be-determined-after-inspection",
                        "name": "يتم تحديد السعر بعد الكشف والزيارة",
                        "cost": "NaN تحديد",
                        "costAfterDiscount": "NaN تحديد"
                    }
                ]
            }
        ]
    },
    {
        "field": {
            "code": "paved",
            "name": "تبليط"
        },
        "maintenanceListPerDevice": [
            {
                "device": null,
                "maintenanceList": [
                    {
                        "code": "price-will-be-determined-after-inspection",
                        "name": "يتم تحديد السعر بعد الكشف والزيارة",
                        "cost": "NaN تحديد",
                        "costAfterDiscount": "NaN تحديد"
                    }
                ]
            }
        ]
    },
    {
        "field": {
            "code": "cameras",
            "name": "كاميرات مراقبة"
        },
        "maintenanceListPerDevice": [
            {
                "device": null,
                "maintenanceList": [
                    {
                        "code": "price-will-be-determined-after-inspection",
                        "name": "يتم تحديد السعر بعد الكشف والزيارة",
                        "cost": "NaN تحديد",
                        "costAfterDiscount": "NaN تحديد"
                    }
                ]
            }
        ]
    },
    {
        "field": {
            "code": "reconditioning",
            "name": "ترميم"
        },
        "maintenanceListPerDevice": [
            {
                "device": null,
                "maintenanceList": [
                    {
                        "code": "price-will-be-determined-after-inspection",
                        "name": "يتم تحديد السعر بعد الكشف والزيارة",
                        "cost": "NaN تحديد",
                        "costAfterDiscount": "NaN تحديد"
                    }
                ]
            }
        ]
    }
]

const PEREVENTIVE_FREQUENCY ={
    monthly: {
        ar: 'شهريا',
        en: 'Monthly'
    },
    quarterly: {
        ar: 'ربع سنوي',
        en: 'Quarterly'
    },
    "semi-annually": {
        ar: 'نصف سنوي',
        en: 'Semi Annually'
    },
    yearly:{
        ar: 'سنوي',
        en: 'Yearly'
    }
  }
  

  export { firebaseConfig, CITIES, FIELDS, SERVICES, PEREVENTIVE_FREQUENCY };