import { useCallback, useEffect, useRef, useState } from "react";
import useDocumentTitle from "../../components/helpers/useDocumentTitle";
import PageHeader from "../../components/shared/PageHeader";
import StepsBar from "../../components/shared/StepsBar";
import MainContent from "../../containers/shared/MainContent";
import PageMain from "../../containers/shared/PageMain";
import apiClient from "../../components/utils/apiClient";
import Button from "../../components/shared/Button";
import SelectUnitsBox from "../../components/property/unit/SelectUnitsBox";
import NormalBoxContainer from "../../containers/shared/NormalBoxContainer";
import { FIELDS, PEREVENTIVE_FREQUENCY } from "../../components/utils/constants";
import MultiSelect from "../../components/shared/MultiSelect";
import moment from 'moment';
import 'moment/locale/ar-sa';
import { Link } from "react-router-dom";
import ModalContainer from "../../containers/shared/ModalContainer";
import InputField from "../../components/shared/InputField";
import Toast from "../../components/shared/Toast";
import NoHeadBoxContainer from "../../containers/shared/NoHeadBoxContainer";
import CounterInput from "../../components/shared/CounterInput";
import { useNavigate } from "react-router-dom";

const PerventiveVisitsAddPage = () => {

    const navigate = useNavigate();
    moment?.locale('ar-sa');
    moment.defineLocale('ar-sa-mine', {
        parentLocale: 'ar-sa',
        preparse: function (string) {
          return string;
        },
        postformat: function (string) {
          return string;
        }
      });

      const DatesList = [...Array(30).keys()].map( (option) => ({
        value: moment().add(option+1, 'days').utc().format("YYYY-MM-DD 00:00:00"),
        label: moment().add(option+1, 'days').format('DD-MM-YYYY')
      }));

      const TimesList = [{label:'من 9 صباحا الي 12 مساءا',value:'من 9 صباحا الي 12 مساءا'},{label:'من 12 مساءا الي 3 مساءا',value:'من 12 مساءا الي 3 مساءا'},{label:'من 3 مساءا الي 6 مساءا',value:'من 3 مساءا الي 6 مساءا'},{label:'من 6 مساءا الي 9 مساءا',value:'من 6 مساءا الي 9 مساءا'}];
    
    const myRef = useRef(null)
    useDocumentTitle('اضافة طلب صيانة');
    const pageTitle = {title: 'اضافة طلب صيانة', subTitle: 'يرجى إتباع الخطوات التاليه لإتمام الطلب الخاص بك'};
    const [toast, setToast] = useState({show: false,type: null, message:'' });
    const [finalServicesList, setFinalServicesList] = useState([]);
    const EmptyCurrentService = {
        index: finalServicesList?.length,
        serviceCode:'',
        serviceName:'',
        subServiceCode:'',
        subServiceName:'',
        serviceTypeCode:'',
        serviceTypeName:'',
        serviceCost:'',
        amount:'',
        units:[]
    };
    const [current, setCurrent] = useState(3);
    const [screen, setScreen] = useState(2);
    const [showShadow, setShowShadow] = useState(false);
    const [properties, setProperties] = useState(null);
    const [services, setServices] = useState(null);
    const [unitsCount, setUnitsCount] = useState(null);
    const [topAllUnits, setTopAllUnits] = useState('none');
    const [totalSelectedUnits, setTotalSelectedUnits] = useState([]);
    const [selectedMainService, setSelectedMainService] = useState(null);
    const [selectedServiceList, setSelectedServiceList] = useState(null);
    const [subServiceTypeOptions, setSubServiceTypeOptions] = useState([]);
    const [selectedSubServiceType, setSelectedSubServiceType] = useState(null);
    const [subServiceOptions, setSubServiceOptions] = useState([]);
    const [unitsListOptions, setUnitsListOptions] = useState([]);
    const [selectedUnitsOptions, setSelectedUnitsOptions] = useState([]);
    const [currentService, setCurrentService] = useState(EmptyCurrentService);
    const [currentSelectedUnits, setCurrentSelectedUnits] = useState([]);
    const [finalSelectedProperties, setFinalSelectedProperties] = useState([]);
    const [selectedDates, setSelectedDates] = useState([]);
    const [finalCoat, setFinalCost] = useState(null);
    const [headerKey, setHeaderKey] = useState(Math.random());
    const [userData, setUserData] = useState(null);
    const [noCreditsModal, setNoCreditsModal] = useState(false);
    const [promoCode, setPromoCode] = useState('');
    const [paymentDone, setPaymentDone] = useState(false);

    const [quantity,setQuantity] = useState(0);
    
    const [completeFieldsToAddAnotherService, setCompleteFieldsToAddAnotherService] = useState(false);

    const [addAnotherServiceStatus,setAddAnotherServiceStatus] = useState(false);
    
    const [startingDay,setStartingDay] = useState('');

    const [frequency,setFrequency] = useState('');

    const [submitDisabled,setSubmitDisabled] = useState(true);


    const frequencyOptions = Object.keys(PEREVENTIVE_FREQUENCY)?.map((option) => ({
        value: option,
        label: PEREVENTIVE_FREQUENCY[option]?.ar
    }));

    const getUnits = async () => {
        try {
          const response = await apiClient.get(`/b-units?business=${userData?.business?._id}`);
          if(response?.data) {
            const options = response?.data?.map( (option) => ({
                value: option?._id,
                label: option?.name,
                phoneNumber: '',
                selectedDay: '',
                selectedTime: '',
                propertyId: option?.propertyId
            }));
            setSelectedUnitsOptions(options);
          } else {
  
          }
        } catch(err) {}
      }

    const getProperties = async () => {
      try {
        const response = await apiClient.get(`/b-properties`);
        if(response?.data) {
          setProperties(response?.data?.properties);
        } else {

        }
      } catch(err) {}
    }


    const getServices = async () => {
        try {
            const response = await apiClient.get(`/misc/configs/v2`);
            if(response?.data) {
                setServices(response?.data?.enums?.services);
            } else {

            }
        } catch(err) {}
    }



    useEffect(()=>{
      getProperties();
      getServices();
    },[]);

    useEffect(()=>{
        if(userData?.business?._id) {
            getUnits();
        }
    },[userData]);


    useEffect(()=>{
      if(properties && Array.isArray(properties) && properties?.length > 0) {
        let unitsCount = 0;
        properties?.map(property=> unitsCount = unitsCount + parseInt(property?.numberOfUnits));
        setUnitsCount(unitsCount);
      }
    },[properties]);

    const addShadowToScroll = (e) => {
        if(e?.target?.scrollTop > 10) {
            setShowShadow(true);
        } else {
            setShowShadow(false);
        }
    }

    const handleClickAllTopButton = () => {
        setTopAllUnits('all');
    }

    const handleClickAllTopButtonReverse = () => {
        setTopAllUnits('none');
    }


    useEffect(()=>{
        if(totalSelectedUnits?.flat(1).length === unitsCount) {
            setTopAllUnits('all');
        }
        if(totalSelectedUnits?.flat(1).length > 0 &&  totalSelectedUnits?.flat(1).length < unitsCount) {
            setTopAllUnits('some');
        }
        if(totalSelectedUnits?.flat(1).length === 0) {
            setTopAllUnits('none');
            setCurrent(1);
        } else {
            setCurrent(2);
        }
        setUnitsListOptions([...Array(totalSelectedUnits?.flat(1).length).keys()].map( (option) => ({
            value: option+1,
            label: option+1
        })));
    },[totalSelectedUnits]);

    const handleClickPrevScreen = (screen) => {
        setScreen(screen);
    }

    const handleClickNextScreen = (screen) => {
        setScreen(screen);
        if(screen === 2) {
            setCurrent(3);
            const getSelectedUnitsIds = totalSelectedUnits?.flat(1)?.map( (unit) => unit?.unitId );
            const getSelectedUnitsObjects = properties?.map((property) => property?.units)?.flat(1).filter( (unit) => getSelectedUnitsIds.includes(unit?._id) );
            const propertiesNames = properties?.map((property)=>({id: property?._id, name: property?.name}));
            const options = getSelectedUnitsObjects.map( (option) => ({
                value: option?._id,
                label: option?.name + ` (${propertiesNames[propertiesNames?.findIndex((property) => property?.id === option?.propertyId)]?.name})`,
                propertyName: propertiesNames[propertiesNames?.findIndex((property) => property?.id === option?.propertyId)]?.name,
                propertyId: option?.propertyId
            }));
            setSelectedUnitsOptions(options);
        }
        if(screen === 3) {
            setCurrent(5);
            const finalSelectedPropertiesList = [];
            finalServicesList?.forEach((service,index)=>{
                service?.units?.forEach((unit,i)=>{
                    finalSelectedPropertiesList[unit?.propertyId] = unit?.propertyName;
                })
            })
            setFinalSelectedProperties(finalSelectedPropertiesList);
        }
        if(screen === 4) {
            setCurrent(7);

            getCostEstimation();

            // console.log(finalServicesList);
            const getCost = finalServicesList?.map((service)=> ({
                total: isNaN(parseInt(service?.serviceCost?.replace('ريال',''))) ? 0 : parseInt(service?.serviceCost?.replace('ريال','')) * service?.amount,
                beforeVat: isNaN(parseInt(service?.serviceCost?.replace('ريال',''))) ? 0 : (parseInt(service?.serviceCost?.replace('ريال','')) * service?.amount) / 1.15,
                vat: isNaN(parseInt(service?.serviceCost?.replace('ريال',''))) ? 0 : (parseInt(service?.serviceCost?.replace('ريال','')) * service?.amount) - ((parseInt(service?.serviceCost?.replace('ريال','')) * service?.amount) / 1.15)
            }))
        
            const getFinalCost = {
                total: getCost.reduce((n, {total}) => n + total, 0).toFixed(0),
                beforeVat: getCost.reduce((n, {beforeVat}) => n + beforeVat, 0).toFixed(0),
                vat: getCost.reduce((n, {vat}) => n + vat, 0).toFixed(0),
            }

            setFinalCost(getFinalCost);
        }
    }

    // console.log(currentService);

    const resetCurrentService = (selected=false) => {
            const newCurrentService = EmptyCurrentService;
            if(selected) {
                newCurrentService.serviceCode = selected?.field;
                newCurrentService.serviceName = selected?.name;
            } else {
                if(selectedMainService) {
                    newCurrentService.serviceCode = selectedMainService?.field;
                    newCurrentService.serviceName = selectedMainService?.name;
                }
            }
            setCurrentService(newCurrentService);
    }



    const handleClickSelectMainService = (field,name,icon) => {
        setSelectedMainService({field: field, name: name,icon: icon});
        const getSelectedServiceList = services.filter(service=>service?.field?.code === field);
        setSelectedServiceList(getSelectedServiceList[0]?.maintenanceListPerDevice);
        resetCurrentService({field:field, name: name});
        setFinalServicesList([]);
        setCurrentSelectedUnits([]);
    }

    const getDeviceOptions = (list) => {
        const options = list?.map((option) => ({
            value: option?.device?.code,
            label: option?.device?.name
        }));
        setSubServiceTypeOptions(options);
    }

    const getSubServiceOptions = (list) => {
        const getList = list.filter(item=>item?.device?.code === selectedSubServiceType)[0]?.maintenanceList;
        const options = getList?.map((option) => ({
            value: option?.code,
            label: option?.name,
            cost: option?.cost
        }));
        setSubServiceOptions(options);
    }

    useEffect(()=>{
        if(selectedServiceList) {
            getDeviceOptions(selectedServiceList);
        }
    },[selectedServiceList])

    useEffect(()=>{
        if(selectedSubServiceType) {
            getSubServiceOptions(selectedServiceList);
        }
    },[selectedSubServiceType])

    const handleSelectServiceType = (option) => {
        setSelectedSubServiceType(option?.value);
        setCurrentService({...currentService, serviceTypeName: option?.label, serviceTypeCode: option?.value, subServiceCode: '', subServiceName: ''});
    }

    const handleSelectSubService = (option) => {
        setCurrentService({...currentService, subServiceName: option?.label, subServiceCode: option?.value, serviceCost: option?.cost});
    }

    const handleSelectUnitsCount = (option) => {
        setCurrentService({...currentService, unitsCount: option?.value});
    }

    const handleSelectUnits = (option) => {
        const unitData = JSON.parse(JSON.stringify(option?.value));
        setCurrentService({...currentService, units: [unitData]});
        const currentUnits = [option?.value]
        setCurrentSelectedUnits(currentUnits);

    }

    // console.log(finalServicesList);
   


    useEffect(()=>{
        if(finalServicesList?.length === currentService?.index) {
            // console.log(Object.keys(currentService).filter(key=> currentService[key] === '' || currentService['amount'] === 0)?.length);
            // console.log(Object.keys(currentService).filter(key=> currentService[key] === '')?.length === 0);
            if((Object.keys(currentService).filter(key=> currentService[key] === '' || currentService['amount'] === 0)?.length === 0)) {
                if(currentService?.units?.length > 0) {
                    const searchEmptyUnits = currentService?.units?.filter(unit=>  unit?.selectedDay === '' || unit?.selectedTime === '');
                    if(searchEmptyUnits?.length === 0) {
                        const updatedServiceList = [...finalServicesList, currentService];
                        setFinalServicesList(updatedServiceList);
                    }
                }
            }
        } else {
            if((Object.keys(currentService).filter(key=> currentService[key] === '' || currentService['amount'] === 0)?.length === 0)) {
                if(currentService?.units?.length > 0) {
                    const searchEmptyUnits = currentService?.units?.filter(unit=>  unit?.selectedDay === '' || unit?.selectedTime === '');
                    if(searchEmptyUnits?.length === 0) {
                        setFinalServicesList(finalServicesList => finalServicesList?.map((service)=> service?.index === currentService?.index ? currentService : service));
                    } else {
                        setFinalServicesList(finalServicesList?.filter((service)=> service?.index !== currentService?.index));
                    }
                } else {
                    setFinalServicesList(finalServicesList?.filter((service)=> service?.index !== currentService?.index));
                }
            } else {
                setFinalServicesList(finalServicesList?.filter((service)=> service?.index !== currentService?.index));
            }
        }
    },[currentService])

   
    useEffect(()=>{
        const checkService = finalServicesList?.filter( service => Object.keys(service).filter(key=> service[key] === '')?.length > 0 );
        if(finalServicesList?.length > 0 && checkService?.length === 0) {
            setCurrent(4);
        } else {
            setCurrent(3);
        }
    },[finalServicesList])


    const handleClickAddNewSubService = () => {
        const getIndexinFinal = finalServicesList?.filter(item=>item?.index === currentService?.index);
        if(getIndexinFinal?.length > 0) {
            resetCurrentService();
            setCurrentSelectedUnits([]);
            setQuantity(0);
        } else {
            setCompleteFieldsToAddAnotherService(true);
        }
    }

    const handleClickEditService = (index) => {
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        const getService = finalServicesList[finalServicesList?.findIndex(s=> s?.index === index)];
        setQuantity(getService?.amount);
        setCurrentService(getService);
        const currentUnits = getService?.units?.map((option) => option?.value);
        setCurrentSelectedUnits(currentUnits);
    }

    const handleChangeDateSelector = (option, id, name) => {
        if(selectedDates?.filter(date=> date?.id === id)?.length > 0){
            setSelectedDates(selectedDates?.map( date => date?.id === id ? {...date, date: option?.value, label: option?.label} : date ));
        } else {
            setSelectedDates(selectedDates => [...selectedDates, {id: id, date: option?.value, name: name, label: option?.label}]);
        }
    }

    useEffect(()=>{
        if(selectedDates?.length === Object?.keys(finalSelectedProperties)?.length) {
            setCurrent(6);
        } else {
            setCurrent(5);
        }
    },[selectedDates])



    useEffect(() => {
        const onFocus = (event) => {
            setHeaderKey(Math.random());
        };
          
        window.addEventListener('focus', onFocus);
        
        return () => {
          window.removeEventListener('focus', onFocus);
        }
      }, []);


      const handleGetUser = (user) => {
        setUserData(user);
      }

      const handleClickSubmitRequest = () => {
            postRequest();
      }

      const postRequest = async () => {

        const getFinalUnitObject = [];
        finalServicesList?.map((service) => {
            service?.units?.map((unit) => {
                getFinalUnitObject.push({
                    "unitId": unit?.value,
                    "propertyId": unit?.propertyId,
                    "serviceDetails": {
                    "serviceCode": service?.serviceCode,
                    "subServiceCode": service?.subServiceCode
                    },
                    "startWorkingDate": unit?.selectedDay,
                    "prefferedSlot": unit?.selectedTime,
                    "contactPhoneNumber": '+966'+unit?.phoneNumber
                })
            })
        });


        const newUnitsScheme = [];
        finalServicesList?.map((service,index)=>{
            service?.units?.map((unit,i)=>{
                if(newUnitsScheme[newUnitsScheme?.findIndex(u=>u?.unitId === unit?.value)]) {
                    newUnitsScheme[newUnitsScheme?.findIndex(u=>u?.unitId === unit?.value)]?.maintenanceList?.push(
                        {
                            "deviceCode": service?.serviceTypeCode,
                            "maintenanceCode": service?.subServiceCode,
                            "amount": parseInt(service?.amount)
                          }
                    )
                } else {
                    newUnitsScheme?.push({
                        "unitId": unit?.value,
                        "propertyId": unit?.propertyId,
                        "maintenanceList": [
                          {
                            "deviceCode": service?.serviceTypeCode,
                            "maintenanceCode": service?.subServiceCode,
                            "amount": parseInt(service?.amount)
                          }
                        ],
                        "startWorkingDate": unit?.selectedDay,
                        "prefferedSlot": unit?.selectedTime,
                        "contactPhoneNumber": '+966'+unit?.phoneNumber
                      })
                }
            })
        })

        
        const body = {
            "field": selectedMainService?.field,
            "units": currentSelectedUnits,
            "startingDate": startingDay,
            "frequency": frequency
          }


        try {
        const response = await apiClient.post(`/b-preventive-visits`,body);
        if(response?.status === 201) {
            navigate(`/ar/preventive-visits`);
        } else {
        }
        } catch(err) {
            setToast({show:true,type:'error',message:err?.response?.data?.message})
        }
            
    }

      const handleCloseModal = () => {
        setNoCreditsModal(false);
      }

      const handleCloseModalAlert = () => {
        setCompleteFieldsToAddAnotherService(false);
      }


      const getCostEstimation = async () => {
        

        const getFinalUnitObject = [];
        finalServicesList?.map((service) => {
            service?.units?.map((unit) => {
                getFinalUnitObject.push({
                    "unitId": unit?.value,
                    "propertyId": unit?.propertyId,
                    "serviceDetails": {
                    "serviceCode": service?.serviceCode,
                    "subServiceCode": service?.subServiceCode
                    },
                    "startWorkingDate": selectedDates[selectedDates?.findIndex((date) => date?.id === unit?.propertyId)]?.date
                })
            })
        });


        const maintenanceList = [];
        finalServicesList?.map((service,index)=>{
            service?.units?.map((unit,i)=>{
                maintenanceList?.push(
                        {
                            "deviceCode": service?.serviceTypeCode,
                            "maintenanceCode": service?.subServiceCode,
                            "amount": parseInt(service?.amount)
                          }
                    )
            })
        })

        let body = {
            "maintenanceList": maintenanceList,
            "requestId": ""
        }
        
        // console.log(body);
        // try {
        //     const response = await apiClient.post(`/requests/worker/costEstimation`,body);
        //     if(response?.data || response?.status === 201) {
        //         console.log(response?.data);
        //     } else {
        //     }
        //     } catch(err) {
        //         setToast({show:true,type:'error',message:err?.response?.data?.message})
        //     }
      }
      

    //   console.log(currentService);
    //   console.log(currentSelectedUnits);
    //   console.log(finalServicesList);
      

    useEffect(()=>{
        const getIndexinFinal = finalServicesList?.filter(item=>item?.index === currentService?.index);
        if(getIndexinFinal?.length > 0) {
            setAddAnotherServiceStatus(true);
        } else {
            setAddAnotherServiceStatus(false);
        }
    },[finalServicesList])

    useEffect(()=>{
        setCurrentService({...currentService,amount: quantity})
    },[quantity])


    useEffect(()=>{
        if(startingDay && frequency && selectedMainService?.field && currentSelectedUnits && Array.isArray(currentSelectedUnits) && currentSelectedUnits?.length > 0) {
            setSubmitDisabled(false);
        } else {
            setSubmitDisabled(true);
        }
    },[startingDay,frequency,selectedMainService,currentSelectedUnits])


    return (
        <div>
            <PageHeader pageTitle={pageTitle} key={headerKey} userCallback={handleGetUser} />
            <PageMain>
                <MainContent noMargin={true} >
                    { !paymentDone ? 
                    properties && services && <>
                    {/* <StepsBar current={properties?.length && unitsCount > 0 ? current : 1} /> */}
                    {properties?.length && unitsCount > 0 ? screen === 1 && 
                    <div className="flex items-stretch content-between flex-wrap pt-[16px]">
                        <div className={`h-[100px] w-full border-t-[8px] bg-white border-t-grey-50 px-[25px] relative`}>
                            {showShadow && <>
                            <span className={`block shadow-[0_4px_24px_0_rgba(0,0,0,0.1)] w-full h-[50px] absolute bottom-0 start-0 end-0 z-[1]`}></span>
                            <span className={`block w-full h-[60px] absolute top-0 start-0 end-0 bg-white z-[1]`}></span>
                            </>}
                            <h2 className="text-darkBlue font-[600] text-[24px] pt-[24px] mb-[12px] leading-[20px] relative z-[2]">إختيار الوحدات</h2>
                            <p className="text-grey-950 font-[500] text-[13px]">يرجى اختيار الوحدات التي ترغب في تحديد الخدمات الفنية عليها من القائمة أدناه.</p>
                        </div>
                        <div className="simpleScroll grow w-full min-h-[300px] px-[10px]" dir="ltr" onScroll={e=>addShadowToScroll(e)}>
                            <div className="px-[15px] min-h-[500px]" dir="rtl">
                                <div className="flex flex-nowrap my-[15px]">
                                    <div className="w-[50%]"><h3 className="text-[20px] font-[600]">كل الوحدات <span className="text-orange">{`(${unitsCount} وحدات)`}</span></h3></div>
                                    <div className="w-[50%] text-end">
                                        {topAllUnits !== 'all' 
                                            ? <Button text={'إختار كل الوحدات'} bgColor="bg-white" textColor="!text-grey-800" wrapperClass="inline-block !max-w-[194px] !m-0 !h-[48px]" customClass="!h-[48px] !leading-[48px] border-[1px] border-grey-100" widthClass="!max-w-[194px]" onClick={  handleClickAllTopButton  } />
                                            : <div className="cursor-pointer inline-block w-[194px] !m-0 !h-[48px] leading-[48px] rounded-[10px] relative bg-selectedcard border-[1px] border-blue text-center text-[18px] font-[600] text-blue select-none" onClick={ handleClickAllTopButtonReverse }><span className="w-[20px] h-[20px] align-middle !bg-center !bg-no-repeat top-[8px] end-[12px] inline-block me-[10px]" style={{background: "url('/assets/images/check-mark.svg')"}}></span> كل الوحدات</div>}
                                            </div>
                                </div>
                                {properties?.map((property, index)=>{
                                    return (<SelectUnitsBox key={`unitsSelector-${index}`} label={property?.name} allText={`كل الوحدات`} unitsCount={property?.numberOfUnits} propertyId={property?._id} topAllUnits={topAllUnits} setTotalSelectedUnits={setTotalSelectedUnits} index={index} />)   
                                })}
                            </div>
                            <div className="border-t-[23px] border-t-grey-50 mx-[-10px]"></div>
                        </div>
                        <div className="w-full h-[96px]">
                            <div className="flex flex-nowrap px-[25px]">
                                <div className="w-[50%]"><span className="text-[18px] font-[500] text-grey-600 block mt-[34px]">لقد قمت باختيار {totalSelectedUnits?.flat(1).length} وحدات</span></div>
                                <div className="w-[50%] text-end"><div className={`w-[194px] h-[48px] leading-[48px] inline-block text-center mt-[24px] bg-grey-800 text-white font-[600] text-[18px] rounded-[10px] cursor-pointer select-none ${current === 1 && 'opacity-50 pointer-events-none'}`} onClick={()=>handleClickNextScreen(2)}>التالي</div></div>
                            </div>
                        </div>
                    </div> : <>
                    <div className="pt-[80px] text-center">
                        <div className="inline-block w-[188px] h-[188px] !bg-contain !bg-center" style={{background: "url('/assets/images/main-property-icon.svg')"}}></div>
                        <div className="w-full text-center text-grey-900 text-[24px] font-[500] mb-[25px]">لم تقم بإضافة اي منشآت حتي الأن</div>
                        <Link className="inline-block min-w-[320px] h-[48px] leading-[48px] rounded-[8px] text-white bg-grey-800 text-[18px] font-[600]" to="/ar/property/add">إنشاء منشأة جديدة</Link>
                    </div>
                    </>}
                    {screen === 2 && 
                    <div className="flex items-stretch content-between flex-wrap">
                        <div className={`h-[100px] w-full border-t-[8px] bg-white border-t-grey-50 px-[25px] relative`}>
                            {showShadow && <>
                            <span className={`block shadow-[0_4px_24px_0_rgba(0,0,0,0.1)] w-full h-[50px] absolute bottom-0 start-0 end-0 z-[1]`}></span>
                            <span className={`block w-full h-[60px] absolute top-0 start-0 end-0 bg-white z-[1]`}></span>
                            </>}
                            <div className="h-9 justify-end items-center gap-3 inline-flex pt-[24px]">
                                {/* <div className="h-[27px] px-2 bg-lightBlue rounded-[30px] shadow flex-col justify-center items-center gap-2.5 inline-flex">
                                    <div className="self-stretch text-right text-darkBlue text-lg font-bold">1</div>
                                </div> */}
                                <div className="text-right text-darkBlue text-2xl font-semibold">تحديد الخدمة الاساسية المطلوبة </div>
                            </div>
                            <div className="w-[391px] text-right text-[#2d3859] text-[13px] font-medium leading-tight pt-[12px]">نأمل منكم تحديد الخدمة التي تحتاجونها. </div>
                        </div>
                        <div className="simpleScroll grow w-full px-[10px]" dir="ltr" onScroll={e=>addShadowToScroll(e)}>
                            <div className="" dir="rtl">
                                <div className="my-[15px]">
                                    <NormalBoxContainer label="من فضلك قم باختيار الخدمات المطلوبة للوحدات التى قمت بتحديدها" heading=" ">
                                        <div className="flex gap-[20px] justify-center flex-wrap">
                                        {Object.keys(FIELDS)?.map((field,index)=>{
                                         return (<div className="w-[calc(20%-20px)]" key={`field-${index}`}>
                                            <div className={`cursor-pointer select-none text-center h-[48px] leading-[48px] rounded-[8px] text-[16px] border-[1px] ${selectedMainService?.field === field ? 'bg-grey-100 border-[1px] text-blue border-blue' : 'bg-grey-100 text-grey-600 border-[transparent]'}`} onClick={()=>handleClickSelectMainService(field,FIELDS[field]?.ar,FIELDS[field]?.icon)}>
                                                <span className={`inline-block align-middle me-[5px] w-[20px] h-[20px] !bg-center !bg-contain ${selectedMainService?.field === field ? 'bg-blue' : 'bg-grey-600'}`} style={{maskImage:"url('/assets/images/"+FIELDS[field]?.icon+"')", maskSize: "contain", maskRepeat: "no-repeat"}}></span>
                                                {FIELDS[field]?.ar}
                                            </div>
                                         </div>)   
                                        })}
                                        </div>
                                    </NormalBoxContainer>

                                    <div className="min-h-[400px]" ref={myRef}>
                                            <div className="px-[15px] mb-[15px]">
                                                <div className="h-9 justify-end items-center gap-3 inline-flex pt-[24px]">
                                                    {/* <div className="h-[27px] px-2 bg-lightBlue rounded-[30px] shadow flex-col justify-center items-center gap-2.5 inline-flex">
                                                        <div className="self-stretch text-right text-darkBlue text-lg font-bold">2</div>
                                                    </div> */}
                                                    <div className="text-right text-darkBlue text-2xl font-semibold">إختيار الوحدة المطلوب عمل الخدمة لها.</div>
                                                </div>
                                                <div className="w-[391px] text-right text-[#2d3859] text-[13px] font-medium leading-tight pt-[12px]">من فضلك قم باختيار الوحدة</div>
                                            </div>
                                            {/* <div className="flex flex-nowrap px-[15px] my-[15px] bg-darkBlue rounded-[8px] mx-[15px]">
                                                <div className="w-[75%]">
                                                    <p className="text-[20px] font-[600] text-white my-[15px] leading-[30px]">من فضلك قم باختيار الخدمات الفرعية المطلوبة في {selectedMainService?.name}.</p>
                                                </div>
                                                <div className="w-[25%] text-end">
                                                    <div className={`inline-block cursor-pointer select-none text-center rounded-[8px] h-[40px] leading-[40px] border-[1px] border-grey-100 w-[178px] mt-[10px] text-[18px] font-[600] text-white ${addAnotherServiceStatus ? 'opacity-100' : 'opacity-50'}`} onClick={()=>handleClickAddNewSubService()}>
                                                        <span className={`inline-block align-sub w-[20px] h-[20px] !bg-center !bg-no-repeat me-[8px] ${addAnotherServiceStatus ? 'bg-orange' : 'bg-white'}`} style={{maskImage:"url('/assets/images/plus.svg')",maskSize:"18px", maskPosition:"top", maskRepeat:"no-repeat"}}></span>
                                                        أضف خدمه أخرى
                                                    </div>
                                                </div>
                                            </div> */}
                                            <NormalBoxContainer label={`من فضلك قم باختيار الخدمات الفرعية المطلوبة في ${selectedMainService?.name || 'الخدمة الاساسية'}.`}>
                                                
                                                <div className="mt-[5px]">
                                                    <p className="text-grey-600 text-[16px] font-[700]">إختيار الوحدة المطلوب عمل الخدمة لها.</p>
                                                    <div className="w-full">
                                                        <MultiSelect classNamePrefix="MaxHSelect" options={selectedUnitsOptions} label={' '} placeholder={'اسم الوحدة'} onChange={(option)=>handleSelectUnits(option)} value={currentSelectedUnits} /> 
                                                    </div>
                                                </div>
                                                
                                            </NormalBoxContainer>

                                            <div className="px-[15px] mb-[15px]">
                                                <div className="h-9 justify-end items-center gap-3 inline-flex pt-[24px]">
                                                    {/* <div className="h-[27px] px-2 bg-lightBlue rounded-[30px] shadow flex-col justify-center items-center gap-2.5 inline-flex">
                                                        <div className="self-stretch text-right text-darkBlue text-lg font-bold">2</div>
                                                    </div> */}
                                                    <div className="text-right text-darkBlue text-2xl font-semibold">تحديد ميعاد وتكرار الخدمة</div>
                                                </div>
                                                <div className="w-[391px] text-right text-[#2d3859] text-[13px] font-medium leading-tight pt-[12px]">من فضلك قم باختبار الخدمة الفرعية المراد تنفيذها</div>
                                            </div>

                                            <div className="flex flex-nowrap my-[15px] rounded-[8px] mx-[15px] gap-[15px]">
                                                <div className="w-[50%]"><MultiSelect options={DatesList} value={startingDay} label={'اختر ميعاد بدء الخدمة'} placeholder={'اختر يوم بدء الخدمة'} onChange={(option)=>setStartingDay( option?.value )} /></div>
                                                <div className="w-[50%]"><MultiSelect options={frequencyOptions} value={frequency} label={'اختر مدي تكرار الخدمة الشهري'} placeholder={'اختر عدد الشهور التي تريد تكرار الخدمة بها '} onChange={(option)=>setFrequency( option?.value )} /></div>
                                            </div>
                                                   


                                            {/* <div className="flex flex-nowrap px-[15px]">
                                                <div className="w-full">
                                                    <p className="text-[20px] font-[600] text-grey-900 my-[15px] leading-[30px]">إختيار الوحدات المناسبه للخدمه</p>
                                                </div>
                                            </div>
                                            <NormalBoxContainer label={`إختيار الوحدات المطلوبه، ويمكنك إضافة أكتر من وحده على نفس الخدمه`}>
                                                <div className="flex flex-nowrap gap-x-[15px]">
                                                    <div className="w-full">
                                                        <MultiSelect isMulti classNamePrefix="MaxHSelect" options={selectedUnitsOptions} label={'إختار الوحده'} placeholder={'اختر...'} onChange={(option)=>handleSelectUnits(option)} value={currentSelectedUnits} /> 
                                                    </div>
                                                </div>
                                            </NormalBoxContainer> */}
                                            
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="border-t-[23px] border-t-grey-50 mx-[-10px]"></div>
                            {finalServicesList?.length > 0 && <>
                                <div className="border-t-[1px] border-t-[#eee] mx-[-10px] px-[15px] p-[24px]" dir="rtl">
                                    <p className="text-[20px] text-grey-900 font-[600] leading-[30px] mb-[15px]">الخدمات التي تم تحديدها</p>
                                    {finalServicesList?.map((service,index)=>{
                                        return (<div key={`SelectedServiceList-${index}`} className="p-[16px] rounded-[8px] bg-grey-50 mb-[15px]">
                                            <div className="flex flex-nowrap mb-[10px]">
                                                <div className="w-[50%] text-[16px] text-grey-900 font-[600]">تفاصيل الخدمه</div>
                                                <div className="w-[50%] text-end"><div className="inline-block cursor-pointer select-none bg-white w-[82px]  border-[1px] border-grey-300 h-[32px] leading-[32px] rounded-[8px] text-[14px] font-[600] text-blue text-center" onClick={()=>handleClickEditService(service?.index)}><span className="w-[16px] h-[16px] !bg-center !bg-contain inline-block align-middle me-[3px] bg-blue" style={{maskImage:"url('/assets/images/edit.svg')", maskSize: "contain", maskRepeat: "no-repeat"}}></span> تعديل</div></div>
                                            </div>
                                            <div className="flex flex-nowrap gap-x-[10px] mb-[15px]">
                                                <div className="w-full max-w-[228px]">
                                                    <div className="inline-block align-middle w-[32px] h-[32px] bg-[#9E9E9E] me-[10px]" style={{maskImage:"url('/assets/images/"+selectedMainService?.icon+"')", maskSize: "contain", maskRepeat: "no-repeat"}}></div>
                                                    <div className="inline-block align-middle">
                                                        <p className="text-[#616161]">الخدمة الأساسية</p>
                                                        <p className="text-[#212121] text-[16px] font-[600]">{selectedMainService?.name}</p>
                                                    </div>
                                                </div>
                                                <div className="w-full max-w-[180px]">
                                                    <p className="text-[#616161]">نوع الخدمة</p>
                                                    <p className="text-[#212121] text-[16px] font-[600]">{service?.serviceTypeName}</p>
                                                </div>
                                                <div className="w-full max-w-[280px]">
                                                    <p className="text-[#616161]">أسم الخدمة الفرعية</p>
                                                    <p className="text-[#212121] text-[16px] font-[600]">{service?.subServiceName || <span className="text-danger">يجب اختيار الخدمة الفرعية</span>}</p>
                                                </div>
                                                <div className="w-full max-w-[280px]">
                                                    <p className="text-[#616161]">الكمية</p>
                                                    <p className="text-[#212121] text-[16px] font-[600]">{service?.amount}</p>
                                                </div>
                                            </div>
                                            <div className="flex flex-nowrap mb-[10px]">
                                                <div className="w-full text-[16px] text-grey-900 font-[600]">الوحدات والمواعيد</div>
                                            </div>
                                            <div>
                                                {service?.units?.map((unit,i)=>{
                                                    return (<div key={`SelectedServiceList-${index}-unit-${i}`} className="inline-block align-middle px-[8px] rounded-[8px] h-[32px] leading-[32px] text-darkBlue font-[500] text-[16px] bg-grey-100 me-[10px]">
                                                        {unit?.label} <span className="text-grey-600 text-[14px] font-[500]">{moment(unit?.selectedDay).format('DD-MM-YYYY')} / {unit?.selectedTime ? unit?.selectedTime : 'اليوم الحالي'}</span>
                                                    </div>)
                                                })}
                                                {/* {service?.units?.length !== service?.unitsCount && <p className="text-danger font-[600] text-[16px] leading-[35px]">يجب ان تكون عدد الوحدات مطابقة للوحدات المختارة بالفعل</p>} */}
                                            </div>
                                        </div>)
                                    })}
                                </div>
                                <div className="border-t-[23px] border-t-grey-50 mx-[-10px]"></div>
                            </>}
                        </div>
                        <div className="w-full h-[96px]">
                            <div className="flex flex-nowrap px-[25px]">
                                <div className="w-[50%]"></div>
                                <div className="w-[50%] text-end">
                                    {/* <div className={`w-[194px] h-[48px] leading-[48px] inline-block text-center mt-[24px] bg-grey-800 text-white font-[600] text-[18px] rounded-[10px] cursor-pointer select-none me-[10px]`} onClick={()=>handleClickPrevScreen(1)}>السابق</div> */}
                                    <div className={`w-[194px] h-[48px] leading-[48px] inline-block text-center mt-[24px] bg-grey-800 text-white font-[600] text-[18px] rounded-[10px] cursor-pointer select-none ${submitDisabled && 'opacity-50 pointer-events-none'}`} onClick={()=>handleClickSubmitRequest()}>ارسال الطلب</div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    {screen === 3 && 
                    <div className="flex items-stretch content-between flex-wrap pt-[16px]">
                        <div className={`h-[100px] w-full border-t-[8px] bg-white border-t-grey-50 px-[25px] relative`}>
                            {showShadow && <>
                            <span className={`block shadow-[0_4px_24px_0_rgba(0,0,0,0.1)] w-full h-[50px] absolute bottom-0 start-0 end-0 z-[1]`}></span>
                            <span className={`block w-full h-[60px] absolute top-0 start-0 end-0 bg-white z-[1]`}></span>
                            </>}
                            <h2 className="text-darkBlue font-[600] text-[24px] pt-[24px] mb-[12px] leading-[20px] relative z-[2]">تحديد توقيت بدء الخدمة</h2>
                            <p className="text-grey-950 font-[500] text-[13px]">من فضلك قم باختيار التوقيت المناسب لك بدء الخدمات المختارة وسنقوم بتاكيد تلك المواعيد بعد استلام الطلب</p>
                        </div>
                        <div className="simpleScroll grow w-full px-[10px] bg-grey-50" dir="ltr" onScroll={e=>addShadowToScroll(e)}>
                            <div className="" dir="rtl">
                                <div className={`my-[15px] relative min-h-[490px] pt-[5px]`} style={{paddingBottom:46+(selectedDates?.length*32)+'px'}}>
                                {Object?.keys(finalSelectedProperties)?.map((key)=>{
                                    return (
                                    <div key={`selecteDate-${key}`}>
                                        <NormalBoxContainer label={`من فضلك قم باختيار اليوم المناسب لبدء الخدمات المطلوبة في “ ${finalSelectedProperties[key]} ”`} leftedLabel={`عرض الايام من ${moment().add(1, 'days').format('DD MMMM')} حتي ${moment().add(30, 'days').format('DD MMMM')}`}>
                                            <MultiSelect options={DatesList} label={'اختر الييوم االمناسب لك'} placeholder={'اختر اليوم'} onChange={(option)=>handleChangeDateSelector(option,key,finalSelectedProperties[key])} value={selectedDates[selectedDates?.findIndex(date=>date?.id === key)]?.date} />
                                        </NormalBoxContainer>
                                        <div className="mb-[15px]"></div>
                                    </div>
                                    )
                                })}

                                {selectedDates?.length > 0 && <div className="absolute bottom-[16px] left-[16px] right-[16px] rounded-[8px] p-[8px_16px] bg-grey-200">
                                    {selectedDates?.map((date,index)=>{
                                        return (<p key={`selectDateText-${index}`} className="font-[500] text-[18px] text-grey-600 leading-[27px] mb-[5px]"><span className="text-danger">*</span>لقد قمت باختيار توقيت البدء في تنفيذ الخدمة لوحدات {date?.name} يوم {date?.label}</p>)
                                    })}
                                </div>}

                                </div>
                            </div>
                            <div className="border-t-[23px] border-t-grey-50 mx-[-10px]"></div>
                        </div>
                        <div className="w-full h-[96px]">
                            <div className="flex flex-nowrap px-[25px]">
                                <div className="w-[50%]"><span className="text-[18px] font-[500] text-grey-600 block mt-[34px]">{ selectedDates?.length === Object?.keys(finalSelectedProperties)?.length ? 'لقد قمت باختيار توقيت البدء ' : 'لم تقم باختيار التاريخ بعد'}</span></div>
                                <div className="w-[50%] text-end">
                                    <div className={`w-[194px] h-[48px] leading-[48px] inline-block text-center mt-[24px] bg-grey-800 text-white font-[600] text-[18px] rounded-[10px] cursor-pointer select-none me-[10px]`} onClick={()=>handleClickPrevScreen(2)}>السابق</div>
                                    <div className={`w-[194px] h-[48px] leading-[48px] inline-block text-center mt-[24px] bg-grey-800 text-white font-[600] text-[18px] rounded-[10px] cursor-pointer select-none ${current === 5 && 'opacity-50 pointer-events-none'}`} onClick={()=>handleClickSubmitRequest()}>التالي</div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    {screen === 4 && 
                    <div className="flex items-stretch content-between flex-wrap">
                        
                        <div className="simpleScroll grow w-full ps-[10px] bg-grey-50" dir="ltr" onScroll={e=>addShadowToScroll(e)}>
                            <div className="" dir="rtl">
                                <div className="relative min-h-[490px]">
                                    <div className="flex flex-nowrap gap-x-[16px] items-start">
                                        <div className="w-[calc(100%-350px)] bg-white p-[24px]">
                                        {finalServicesList?.length > 0 && <>
                                            <div className="h-9 justify-end items-center gap-3 inline-flex pt-[24px]">
                                                <div className="h-[27px] px-2 bg-lightBlue rounded-[30px] shadow flex-col justify-center items-center gap-2.5 inline-flex">
                                                    <div className="self-stretch text-right text-darkBlue text-lg font-bold">3</div>
                                                </div>
                                                <div className="text-right text-darkBlue text-2xl font-semibold">مراجعة الخدمات التي تم اختيارها</div>
                                            </div>
                                            <p className="text-[#2D3859] text-[13px] font-[500] pt-[15px]">من فضلك قم بمراجعة الخدمات التي قمت باضافاتها قبل ارسال الطلب</p>
                                                    <p className="text-[20px] text-grey-900 font-[600] leading-[30px] mb-[25px] mt-[25px]">الخدمات التي تم تحديدها</p>
                                                    {finalServicesList?.map((service,index)=>{
                                                        return (<div key={`SelectedServiceList-${index}`} className="p-[16px] rounded-[8px] bg-grey-50 mb-[15px]">
                                                            <div className="flex flex-nowrap mb-[10px]">
                                                                <div className="w-[50%] text-[16px] text-grey-900 font-[600]">تفاصيل الخدمه</div>
                                                                <div className="w-[50%] text-end"></div>
                                                            </div>
                                                            <div className="flex flex-nowrap gap-x-[10px] mb-[15px]">
                                                                <div className="w-full max-w-[228px]">
                                                                    <div className="inline-block align-middle w-[32px] h-[32px] bg-[#9E9E9E] me-[10px]" style={{maskImage:"url('/assets/images/"+selectedMainService?.icon+"')", maskSize: "contain", maskRepeat: "no-repeat"}}></div>
                                                                    <div className="inline-block align-middle">
                                                                        <p className="text-[#616161]">الخدمة الأساسية</p>
                                                                        <p className="text-[#212121] text-[16px] font-[600]">{selectedMainService?.name}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="w-full max-w-[180px]">
                                                                    <p className="text-[#616161]">نوع الخدمة</p>
                                                                    <p className="text-[#212121] text-[16px] font-[600]">{service?.serviceTypeName}</p>
                                                                </div>
                                                                <div className="w-full max-w-[280px]">
                                                                    <p className="text-[#616161]">أسم الخدمة الفرعية</p>
                                                                    <p className="text-[#212121] text-[16px] font-[600]">{service?.subServiceName || <span className="text-danger">يجب اختيار الخدمة الفرعية</span>}</p>
                                                                </div>
                                                                <div className="w-full max-w-[280px]">
                                                                    <p className="text-[#616161]">الكمية</p>
                                                                    <p className="text-[#212121] text-[16px] font-[600]">{service?.amount}</p>
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-nowrap mb-[10px]">
                                                                <div className="w-full text-[16px] text-grey-900 font-[600]">الوحدات والمواعيد</div>
                                                            </div>
                                                            <div>
                                                                {service?.units?.map((unit,i)=>{
                                                                    return (<div key={`SelectedServiceList-${index}-unit-${i}`} className="inline-block align-middle px-[8px] rounded-[8px] h-[32px] leading-[32px] text-darkBlue font-[500] text-[16px] bg-grey-100 me-[10px]">
                                                                        {unit?.label} <span className="text-grey-600 text-[14px] font-[500]">{moment(unit?.selectedDay).format('DD-MM-YYYY')} / {unit?.selectedTime ? unit?.selectedTime : 'اليوم الحالي'}</span>
                                                                    </div>)
                                                                })}
                                                                {/* {service?.units?.length !== service?.unitsCount && <p className="text-danger font-[600] text-[16px] leading-[35px]">يجب ان تكون عدد الوحدات مطابقة للوحدات المختارة بالفعل</p>} */}
                                                            </div>
                                                        </div>)
                                                    })}
                                            </>}
                                        </div>
                                        <div className="w-[324px] mt-[25px] bg-white rounded-[8px] p-[16px]">
                                                {/* <p className="text-grey-600 text-[16px]">كود الخصم</p>
                                                <div className="relative my-[10px]">
                                                    <InputField placeholder={'اكتب كود الخصم ان وجد'} onChange={e=>setPromoCode(e?.target?.value)} />
                                                    <div className="cursor-pointer select-none text-grey-900 text-[16px] font-[500] w-[81px] text-center h-[48px] leading-[48px] bg-orange absolute top-0 end-0 rounded-e-[8px]">تفعيل</div>
                                                </div> */}
                                                {/* <div className="flex pt-[15px]">
                                                    <div className="w-[25%]">
                                                        <p className="text-grey-900 text-[16px] font-[500]" dir="ltr">0 sr</p>
                                                    </div>
                                                </div> */}
                                                <div className="flex border-b-dotted border-b-[1px] border-b-grey-300 py-[15px]">
                                                    <div className="w-[75%]">
                                                        <p className="text-grey-900 text-[16px] font-[500]">اجمالي راتب العمالة</p>
                                                    </div>
                                                    <div className="w-[25%]">
                                                        <p className="text-grey-900 text-[16px] font-[500]" dir="ltr">{finalCoat?.beforeVat} sr</p>
                                                    </div>
                                                </div>
                                                <div className="flex pt-[15px]">
                                                    <div className="w-[75%]">
                                                        <p className="text-grey-900 text-[16px] font-[500]">المجموع</p>
                                                    </div>
                                                    <div className="w-[25%]">
                                                        <p className="text-grey-900 text-[16px] font-[500]" dir="ltr">{finalCoat?.beforeVat} sr</p>
                                                    </div>
                                                </div>
                                                <div className="flex pt-[15px]">
                                                    <div className="w-[75%]">
                                                        <p className="text-grey-900 text-[16px] font-[500]">الضريبة</p>
                                                    </div>
                                                    <div className="w-[25%]">
                                                        <p className="text-grey-900 text-[16px] font-[500]" dir="ltr">{finalCoat?.vat} sr</p>
                                                    </div>
                                                </div>
                                                <div className="flex border-b-dotted border-b-[1px] border-b-grey-300 py-[15px]">
                                                    <div className="w-[75%]">
                                                        <p className="text-grey-900 text-[16px] font-[500]">الخصم</p>
                                                    </div>
                                                    <div className="w-[25%]">
                                                        <p className="text-grey-900 text-[16px] font-[500]" dir="ltr">0 sr</p>
                                                    </div>
                                                </div>
                                                <div className="flex pt-[15px]">
                                                    <div className="w-[75%]">
                                                        <p className="text-grey-900 text-[16px] font-[500]">اجمالي ما سيتم دفعه</p>
                                                    </div>
                                                    <div className="w-[25%]">
                                                        <p className="text-grey-900 text-[18px] font-[700]" dir="ltr">{finalCoat?.total} sr</p>
                                                    </div>
                                                </div>
                                                {userData?.business?.credits >= finalCoat?.total 
                                                ? <div className="text-success text-[14px] text-center font-[500] leading-[21px] my-[15px]">
                                                رصيد المحفظة كافي لاتمام الطلب
                                                </div>
                                                : <div className="flex flex-nowrap gap-x-[5px]">
                                                    <div className="w-[calc(100%-95px)] text-danger text-[14px] font-[500] leading-[21px] my-[15px]">
                                                    رصيد المحفظة غير كافي لاتمام الطلب
                                                    </div>
                                                    <div className="w-[90px] text-end pt-[10px]">
                                                        <Link className="inline-block bg-blue text-white p-[4px_8px] text-[14px] rounded-[8px]" to="/ar/wallet" target="_blank" rel="noopener noreferrer">شحن المحفظة</Link>
                                                    </div>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="border-t-[23px] border-t-grey-50 mx-[-10px]"></div>
                        </div>
                        <div className="w-full h-[96px]">
                            <div className="flex flex-nowrap px-[25px]">
                                <div className="w-[50%]"><span className="text-[18px] font-[500] text-grey-600 block mt-[34px]">بضغطك ارسال الطلب تكون قد وافقت عليي تفاصيل الطلب وترغب في التنفيذ</span></div>
                                <div className="w-[50%] text-end">
                                <div className={`w-[194px] h-[48px] leading-[48px] inline-block text-center mt-[24px] bg-white text-blue border-[1px] border-blue font-[600] text-[18px] rounded-[10px] cursor-pointer select-none me-[10px]`} onClick={()=>handleClickPrevScreen(2)}>السابق</div>
                                    <div className={`w-[194px] h-[48px] leading-[48px] inline-block text-center mt-[24px] bg-grey-800 text-white font-[600] text-[18px] rounded-[10px] cursor-pointer select-none`} onClick={()=>handleClickSubmitRequest()}>ارسال الطلب</div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    </>
                    : <div className="bg-grey-50 h-[calc(100vh-135px)] pt-[80px]">
                        <div className="w-[128px] h-[128px] mx-auto !bg-center !bg-no-repeat" style={{background:"url('/assets/images/success.svg')"}}></div>
                        <div className="max-w-[600px] rounded-s-[16px] bg-white mt-[60px] mx-auto text-center text-grey-900 pt-[25px]">
                            <p className="font-[700] text-[30px] mb-[15px]">تهانينا !</p>
                            <p className="font-[500] text-[24px]">لقد قمت ارسال طلبك بنجاح.</p>
                            <p className="font-[500] text-[16px] py-[20px]">سيقوم فريق عمانا بالتواصل معك في اقرب وقت ممكن لتاكيد الطلب.</p>
                            <p className="font-[500] text-[16px] pb-[15px]">اذا اردت مراجعة طلباتك من فضلك توجه الي الطلبات.</p>
                            <div className="flex flex-nowrap h-[40px] leading-[40px] bg-grey-200 text-grey-600 text-[14px] font-[500] border-b-[1px] border-b-grey-100 px-[15px]">
                                <div className="w-[50%] text-start">توقيت البدء</div>
                                <div className="w-[50%] text-end">{selectedDates[0]?.label}</div>
                            </div>
                            <div className="flex flex-nowrap h-[40px] leading-[40px] bg-grey-200 text-grey-600 text-[14px] font-[500] px-[15px]">
                                <div className="w-[50%] text-start">اجماليي الطلب</div>
                                <div className="w-[50%] text-start" dir="ltr">{finalCoat?.total} sr</div>
                            </div>
                            <div className="flex flex-nowrap h-[96px] bg-grey-100">
                                <div className="w-[50%] text-center">
                                    <Link to="/ar/requests/add" className="bg-blue text-white text-center rounded-[8px] h-[48px] mt-[24px] min-w-[256px] inline-block leading-[48px] font-[600] text-[18px]" reloadDocument>إضافة طلب جديد</Link>
                                </div>
                                <div className="w-[50%] text-center">
                                    <Link to="/ar/requests" className="bg-grey-800 text-white text-center rounded-[8px] h-[48px] mt-[24px] min-w-[256px] inline-block leading-[48px] font-[600] text-[18px]">عرض الطلبات</Link>
                                </div>
                            </div>
                        </div>
                    </div> }       
                </MainContent>
            </PageMain>

            
      { noCreditsModal && <ModalContainer heading="رصيد المحفظة غير كافي" handleCloseModal={handleCloseModal}>
            <div className="mb-[25px]">
              <p className="text-[16px] text-grey-900">رصيد المحفظة الحالي غير كافي لاتمام الطلب من فضلك قم بشحن الرصيد بقيمة {finalCoat?.total - userData?.business?.credits} ريال لتتمكن من تسجيل طلبك</p>
            </div>
            <div className="text-end">
              <div className="text-grey-400 text-[18px] font-[600] inline-block cursor-pointer select-none me-[50px]" onClick={handleCloseModal}>إلغاء</div>
              <Link className="inline-block bg-grey-800 text-white px-[8px] text-center text-[18px] font-[600] h-[48px] leading-[48px] w-[300px] rounded-[8px]" to="/ar/wallet" target="_blank" rel="noopener noreferrer" onClick={handleCloseModal}>شحن المحفظة</Link>
            </div>
        </ModalContainer> }

        { completeFieldsToAddAnotherService && <ModalContainer heading="تنبية" handleCloseModal={handleCloseModalAlert}>
            <p className="text-[16px] text-grey-900 leading-[24px] font-[400]">من فضلك ادخل الحقول المطلوبة اولا لتتمكن من اضافة خدمة اخري.</p>
            <div className="text-end">
                <div className="text-grey-400 text-[18px] font-[600] inline-block cursor-pointer select-none" onClick={handleCloseModalAlert}>اغلاق</div>
            </div>
        </ModalContainer> }


        <Toast toast={toast} setToast={setToast} />


        </div>
    )
}


export default PerventiveVisitsAddPage;