import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import useDocumentTitle from "../../../components/helpers/useDocumentTitle";
import PageHeader from "../../../components/shared/PageHeader";
import PageMain from "../../../containers/shared/PageMain";
import apiClient from "../../../components/utils/apiClient";
import MainContent from "../../../containers/shared/MainContent";
import NormalFormContainer from "../../../containers/shared/NormalFormContainer";
import InputField from "../../../components/shared/InputField";
import ModalContainer from "../../../containers/shared/ModalContainer";
import NormalButton from "../../../components/shared/NormalButton";
import TextArea from "../../../components/shared/TextArea";
import SingleImageCompress from "../../../components/helpers/SingleImageCompress";
import Loading from "../../../components/shared/Loading";
import Toast from "../../../components/shared/Toast";
import MapAutocomplete from "../../../components/shared/MapAutocomplete";
import CityConverter from "../../../components/helpers/CityConverter";


const UnitEditPage = () => {
    const {t,i18n} = useTranslation();
    useDocumentTitle('تعديل وحدة');
    const { id } = useParams();
    const navigate = useNavigate();
    const pageTitle = {title: 'تعديل وحدة', subTitle: 'يمكنك تعديل وحداتك من هنا'};
    const [unit, setUnit] = useState(null);
    const [editUnitForm,setEditUnitForm] = useState(null);
    const [errors, setErrors] = useState(null);
    const [isEditUnitFormDisabled, setIsEditUnitFormDisabled] = useState(true);
    const [showLoading, setShowLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [UnitId, setUnitId] = useState(null);
    const [toast, setToast] = useState({show: false,type: null, message:'' });
    const [addressDetails, setAddressDetails] = useState(null);
    const [deleteUnitModal, setDeleteUnitModal] = useState(false);
    const [propertyId, setPropertyId] = useState(null); 
    const [showNotSupportedCity, setShowNotSupportedCity] = useState(false);
    const [property, setProperty] = useState(null);



    const getProperty = async () => {
        try {
          const response = await apiClient.get(`/b-properties/${unit?.propertyId}`);
          if(response?.data) {
            setProperty(response?.data);
          } else {
  
          }
        } catch(err) {}
      }

    

    const getUnit = async () => {
        try {
          const response = await apiClient.get(`/b-units/${id}`);
          if(response?.data) {
            const finalUnit = JSON.parse(JSON.stringify(response?.data));
            finalUnit.address = finalUnit.addressDetails.addressLine;
            setEditUnitForm(finalUnit);
            setUnit(response?.data);
            setPropertyId(response?.data?.propertyId);
            setAddressDetails(response?.data?.addressDetails);
          } else {
  
          }
        } catch(err) {}
      }

    useEffect(()=>{
        getUnit();
    },[])


    useEffect(()=>{
        if(unit) {
            getProperty();
        }
    },[unit])

  
    useEffect(()=>{
      if(editUnitForm && editUnitForm?.name) {
        setIsEditUnitFormDisabled(false);
      } else {
        setIsEditUnitFormDisabled(true);
      }
    },[editUnitForm]);
  
    const hideError = (err) => {
      if(errors) {
        let errorsList = errors;
        delete errorsList[err];
        setErrors(errorsList);
      }
    }


    const handleCloseModal = () => {
        
    }


    const handleSubmitEditUnit = () => {
        setShowLoading(true);
        setIsEditUnitFormDisabled(true);
        handleSubmitEditUnitAction();
    }

    const handleSubmitEditUnitAction = async () => {
        setErrors(null);
        const finalEditUnitForm = editUnitForm;
        finalEditUnitForm.addressDetails = addressDetails;
        if(finalEditUnitForm?.addressDetails?.city && (CityConverter(finalEditUnitForm?.addressDetails?.city,'ar') !== 'notFound')) {
            finalEditUnitForm.addressDetails.city = CityConverter(finalEditUnitForm?.addressDetails?.city,'ar');
        }
        finalEditUnitForm.addressDetails.addressLine = finalEditUnitForm?.address;
        delete finalEditUnitForm.deletedAt;
        delete finalEditUnitForm.updatedAt;
        delete finalEditUnitForm.createdAt;
        delete finalEditUnitForm.owners;
        delete finalEditUnitForm.createdBy;
        delete finalEditUnitForm.managers;
        delete finalEditUnitForm.employees;
        delete finalEditUnitForm.id;
        delete finalEditUnitForm._id;
        delete finalEditUnitForm.__v;
        delete finalEditUnitForm.subRequests;
        delete finalEditUnitForm.propertyId;
        if((finalEditUnitForm?.addressDetails?.city && finalEditUnitForm?.addressDetails?.city !== 'notFound') || finalEditUnitForm?.addressDetails === null) {
            try {
                const response = await apiClient.patch(`/b-units/${id}`,finalEditUnitForm);
                if(response?.data) {
                    setShowLoading(false);
                    setUnitId(response?.data?._id);
                    setSuccessMessage(true);
                } else {
                    setIsEditUnitFormDisabled(false);
                    setShowLoading(false);
                }
                } catch(err) {
                    setIsEditUnitFormDisabled(false);
                    setShowLoading(false);
                    if(err?.response?.data?.errors) {
                        let errorsList = {};
                        err?.response?.data?.errors?.map(err=>{
                            errorsList[err.field] = err.message; 
                        });
                        setErrors(errorsList);
                    }
                }
        } else {
            setShowLoading(false);
            setShowNotSupportedCity(true);
        }
        
    }
    

    const handleClickUnitDetails = () => {
        navigate(`/ar/unit/${UnitId}`);
      }

      const handleCloseModalDelete = () => {
        setDeleteUnitModal(false);
      }

      const handleDeleteUnit = () => {
        setDeleteUnitModal(true);
      }

      const handleDeleteUnitAction = async () => {
        try {
            const response = await apiClient.delete(`/b-units/${id}`);
            if(response?.status === 204) {
                navigate(`/ar/property/${propertyId}`);
            }
        } catch(err) {}
      }



  return (
    <>
        <div>
        <PageHeader pageTitle={pageTitle} />
        <PageMain>
            <div className="absolute top-0 left-0 w-full h-full bg-grey-50 z-0"></div>
            <MainContent>
                { editUnitForm && <NormalFormContainer heading="من فضلك قم بملئ البيانات التالية لتعديل الوحدة." label={`تعديل ${property?.propertyType?.unitDisplayName ? property?.propertyType?.unitDisplayName : 'الوحدة'}`}>
                    
                    <div className="mb-[15px]">
                        <InputField label={`اسم ${property?.propertyType?.unitDisplayName ? property?.propertyType?.unitDisplayName : 'الوحدة'}`} placeholder={'اكتب الاسم'} value={editUnitForm?.name} error={errors?.name} errorMessage={errors?.name} translateError={true} onChange={(e)=>{setEditUnitForm({ ...editUnitForm, name: e.target.value }); hideError('name')}} />
                    </div>

                    {/* <div className="mb-[15px]">
                        <TextArea label={'وصف الوحدة'} placeholder={'مثال لنص يمكن استخدامة كوصف للوحدة'} value={editUnitForm?.description} error={errors?.description} errorMessage={errors?.description} translateError={true} onChange={(e)=>{ setEditUnitForm({ ...editUnitForm, description: e.target.value }); hideError('description')}} />
                    </div> */}

                    <div className="mb-[15px]">
                        <InputField isRequird label={`عنوان ${property?.propertyType?.unitDisplayName ? property?.propertyType?.unitDisplayName : 'الوحدة'} الوطني`} placeholder={'اكتب الاسم'} value={editUnitForm?.address} error={errors?.address} errorMessage={errors?.address} translateError={true} onChange={(e)=>{setEditUnitForm({ ...editUnitForm, address: e.target.value }); hideError('address')}} />
                    </div>

                    <div className="mb-[15px]">
                        { editUnitForm?.addressDetails?.location?.latitude && editUnitForm?.addressDetails?.location?.longitude && <MapAutocomplete error={errors?.addressDetails} errorMessage={errors?.addressDetails} setAddressDetails={setAddressDetails} addressDetails={addressDetails} /> }
                    </div>

                    <div className="mb-[15px]">
                        <InputField isRequird label={`اسم المسئول عن ${property?.propertyType?.unitDisplayName ? property?.propertyType?.unitDisplayName : 'الوحدة'}`} placeholder={'اكتب الاسم بالكامل'} value={editUnitForm?.contactName} error={errors?.contactName} errorMessage={errors?.contactName} translateError={true} onChange={(e)=>{setEditUnitForm({ ...editUnitForm, contactName: e.target.value }); hideError('contactName')}} />
                    </div>

                    <div className="mb-[15px]">
                        <InputField isRequird phone={true} label={t("forms.mobileLabel")} value={editUnitForm?.contactPhone?.replace('+966','')} error={errors?.contactPhone} errorMessage={errors?.contactPhone} translateError={true} onChange={(e)=>{ if(e.target.value === '' || /^[0-9\b]+$/.test(e.target.value)) { setEditUnitForm({ ...editUnitForm, contactPhone: '+966'+e.target.value }) }; hideError('contactPhone')}} />
                    </div>

                    <div className="text-end">
                        <NormalButton label={`حذف ${property?.propertyType?.unitDisplayName ? property?.propertyType?.unitDisplayName : 'الوحدة'}`} onClick={handleDeleteUnit} gabEnd color={'!bg-danger'} />
                        <NormalButton label={`تحديث بيانات ${property?.propertyType?.unitDisplayName ? property?.propertyType?.unitDisplayName : 'الوحدة'}`} onClick={handleSubmitEditUnit} isDisabled={isEditUnitFormDisabled} />
                    </div>

                </NormalFormContainer>}
            </MainContent>
        </PageMain>
        </div>
        { successMessage && <ModalContainer hideX={true} maxWidth={'600px'} handleCloseModal={handleCloseModal}>
            <div className="text-center mb-[16px]">
                <span className="block w-[128px] h-[128px] m-auto !bg-center !bg-no-repeat" style={{background:"url('/assets/images/success.svg')"}} ></span>
            </div>
            <div className="text-center text-grey-900 mb-[80px]">
                <p className="font-[700] text-[30px] leading-[185%]">تهانينا !</p>
                <p className="font-[500] text-[24px] leading-[185%]">لقد قمت بتعديل وحدتك بنجاح.</p>
            </div>
            <div className="text-center bg-grey-100 absolute bottom-0 left-0 w-full h-[96px] pt-[24px]">
                <NormalButton label={'الذهاب الي تفاصيل الوحدة'} onClick={handleClickUnitDetails} color={'!bg-grey-800'} />
            </div>
        </ModalContainer> }
        { deleteUnitModal && <ModalContainer heading="حذف وحدة" handleCloseModal={handleCloseModalDelete}>
            <p className="text-[16px] text-grey-900 leading-[24px] font-[400]">هل انت متاكد من حذف وحدة “{editUnitForm?.name}”</p>
            <div className="text-end">
                <div className="text-grey-400 text-[18px] font-[600] inline-block cursor-pointer select-none me-[50px]" onClick={handleCloseModalDelete}>إلغاء</div>
                <NormalButton color={'!bg-danger'} label={'حذف الوحدة'} onClick={handleDeleteUnitAction} />
            </div>
        </ModalContainer> }
        { showNotSupportedCity && <ModalContainer heading="حدث خطأ" handleCloseModal={handleCloseModal}>
            <div>
              عذرا, لقد اخترت مدينة خارج التغطية في الوقت الحالي.
            </div>
            <div className="text-end">
              <div className="text-grey-400 text-[18px] font-[600] inline-block cursor-pointer select-none" onClick={handleCloseModal}>حسنا</div>
            </div>
        </ModalContainer> }
        <Toast toast={toast} setToast={setToast} />
        <Loading showLoading={showLoading} />
    </>
  );
};

export default UnitEditPage;
