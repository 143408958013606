export default function CounterInput({wrapperClass='',value=0,setValue,label,isRequired=false,full=false}){

    return (
        <div className={`w-[100%] mb-[10px] ${full ? '' : 'mt-[24px]'} relative ${wrapperClass}`}>
            <label className={`${full ? '' : 'mb-[3px] block'}`}>{label}{isRequired && <span className="text-danger">*</span>}</label>
            <div className={`flex flex-nowrap h-[48px] leading-[46px] w-full ${full ? '' : 'max-w-[350px]'} select-none rounded-[10px] border-[1px] border-grey-200`}>
                <div className="w-[46px] h-[46px] cursor-pointer select-none" onClick={()=>{value > 0 && setValue(value-1)}}><span className="block w-[46px] h-[46px] select-none !bg-center !bg-no-repeat" style={{background:"url('/assets/images/decrement.svg')"}}></span></div>
                <div className="text-center w-full">{value}</div>
                <div className="w-[46px] h-[46px] cursor-pointer select-none" onClick={()=>{setValue(value+1)}}><span className="block w-[46px] h-[46px] select-none !bg-center !bg-no-repeat" style={{background:"url('/assets/images/increment.svg')"}}></span></div>
            </div>
        </div>
    )
}