import { useAppLatyoutContext } from "../../layouts/appLayout"

export default function Loading({showLoading}){

    const appLatyoutContext = useAppLatyoutContext();

    return(
        showLoading && <div className="fixed w-full h-full top-0 left-0 bg-black/50 select-none z-[100]">
            <div className={`absolute top-0 left-0 h-full flex flex-wrap items-center justify-center content-center ${appLatyoutContext?.navState ? '!w-[calc(100vw-229px)]' : '!w-[calc(100vw-111px)]'}`}>
                <div className="dot-flashing"></div>
                <div className="font-[600] text-white text-[24px] w-full text-center pt-[8px] select-none">جاري تنفيذ الطلب</div>
            </div>
        </div>
    )

}

